import {
  Box,
  FormHelperText,
  TextField,
  Typography,
  FormControlLabel,
  Checkbox,
  Button,
  IconButton,
  InputAdornment,
  OutlinedInput,
  List,
  ListItem,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TextareaAutosize,
  Select,
  MenuItem,
  FormControl,
  Collapse,
  RadioGroup,
  Radio,
  Tabs,
  Tab,
  Switch,
  styled,
  Menu,
  Grid,
  CircularProgress,
  ListItemText,
  AccordionNutrition,
} from "@mui/material";

import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import CrisisAlertIcon from '@mui/icons-material/CrisisAlert';
import { Link } from "react-router-dom";
import Svg from "../assets/Svg";
import Pdf from "../assets/Pdf";
import Png from "../assets/Png";
import Video from "../assets/Video";
import Sidebar from "./defaulLayout/admin/Sidebar";
import Header from "./defaulLayout/admin/Header";
import PersonIcon from "@mui/icons-material/Person";
import PrimaryButton from "./common/PrimaryButton";
import AuthVideo from "./common/AuthVideo";
import OutlineButton from "./common/OutlineButton";
import Pagination from "./common/Pagination";
import Dialog from "@mui/material/Dialog";
import {
  validateName,
  validatePassword,
  validateEmail,
  validateConfirmPassword,
} from "../component/common/AuthValidation";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { DataGrid } from "@mui/x-data-grid";
import AddIcon from "@mui/icons-material/Add";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import DialogActions from "@mui/material/DialogActions";
import DialogTitle from "@mui/material/DialogTitle";
import Stack from "@mui/material/Stack";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import SettingsIcon from "@mui/icons-material/Settings";
import PercentIcon from "@mui/icons-material/Percent";
import UserHeader from "./user/defaulLayout/UserHeader";
import RefferalProgram from "./user/pages/userDashboard/RefferalProgram";
import BlueBorderButton from "./common/BlueBorderButton";
import UserSidebar from "./user/defaulLayout/UserSidebar";
import FitrizerCard from "./user/pages/userDashboard/FitrizerCard";
import DataPicker from "./user/pages/userDashboard/DataPicker";
import Loader from "./common/Loader";
import AuthFooter from "./defaulLayout/user/AuthFooter";
import WalletIcon from "@mui/icons-material/Wallet";
import AddToHomeScreenIcon from "@mui/icons-material/AddToHomeScreen";
import WarehouseIcon from "@mui/icons-material/Warehouse";
import StoreIcon from "@mui/icons-material/Store";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import InventoryIcon from "@mui/icons-material/Inventory";
// import DeleteModals from "./modal/DeleteModal";
import StatusModal from "./modal/StatusModal";
import CMSIcon from "@mui/icons-material/Lock";
import CheckIcon from "@mui/icons-material/Check";
import ClearIcon from "@mui/icons-material/Clear";
import Modal from "@mui/material/Modal";
import { DatePicker } from "@mui/x-date-pickers";
import ModeCommentIcon from "@mui/icons-material/ModeComment";
import SportsHandballIcon from "@mui/icons-material/SportsHandball";
import GroupsIcon from "@mui/icons-material/Groups";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import DialogContent from "@mui/material/DialogContent";
import CloseIcon from "@mui/icons-material/Close";
import KayakingIcon from "@mui/icons-material/Kayaking";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import StackedBarChartIcon from "@mui/icons-material/StackedBarChart";
import ViewListIcon from "@mui/icons-material/ViewList";
import WifiProtectedSetupIcon from "@mui/icons-material/WifiProtectedSetup";
import Groups3Icon from "@mui/icons-material/Groups3";
import ReviewsIcon from '@mui/icons-material/Reviews';
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import InventorySharpIcon from "@mui/icons-material/InventorySharp";
import SettingsAccessibilityIcon from "@mui/icons-material/SettingsAccessibility";
import CardGiftcardIcon from "@mui/icons-material/CardGiftcard";
import AddShoppingCartIcon from "@mui/icons-material/AddShoppingCart";
import ThumbsUpDownOutlinedIcon from "@mui/icons-material/ThumbsUpDownOutlined";
import ReceiptOutlinedIcon from "@mui/icons-material/ReceiptOutlined";
import AccountBalanceWalletOutlinedIcon from "@mui/icons-material/AccountBalanceWalletOutlined";
import ContactMailOutlinedIcon from "@mui/icons-material/ContactMailOutlined";
import AssignmentOutlinedIcon from "@mui/icons-material/AssignmentOutlined";
import SettingsSuggestOutlinedIcon from "@mui/icons-material/SettingsSuggestOutlined";
import AssessmentOutlinedIcon from "@mui/icons-material/AssessmentOutlined";
import LiveHelpIcon from "@mui/icons-material/LiveHelp";
import ReplyIcon from "@mui/icons-material/Reply";
import CircleNotificationsIcon from "@mui/icons-material/CircleNotifications";
import CardMembershipIcon from "@mui/icons-material/CardMembership";
import DinnerDiningIcon from "@mui/icons-material/DinnerDining";
import RamenDiningIcon from "@mui/icons-material/RamenDining";
import FitnessCenterIcon from "@mui/icons-material/FitnessCenter";
import BookOnlineIcon from "@mui/icons-material/BookOnline";
import DownloadIcon from "@mui/icons-material/Download";

import {
  displayDateValue,
  displayDateWithTime,
  displayNumberValue,
  displayNumberValueWithToFixed,
  displayValue,
  downloadReport,
  formatedDateAndTime,
  getStartAndEndDateByType
} from "../common/functions";

export default {
  ModeCommentIcon,
  SportsHandballIcon,
  ArrowBackIcon,
  DialogContent,
  AdapterMoment,
  KayakingIcon,
  CloseIcon,
  DatePicker,
  Modal,
  ViewListIcon,
  WifiProtectedSetupIcon,
  Groups3Icon,
  ReviewsIcon,
  // DeleteModals,
  ArrowDropDownIcon,
  StackedBarChartIcon,
  StatusModal,
  CircularProgress,
  ExpandLess,
  ExpandMore,
  Collapse,
  CheckIcon,
  StoreIcon,
  AddToHomeScreenIcon,
  InventoryIcon,
  WarehouseIcon,
  CMSIcon,
  WalletIcon,
  ClearIcon,
  PercentIcon,
  SettingsIcon,
  AdapterDayjs,
  LocalizationProvider,
  DesktopDatePicker,
  Stack,
  DialogTitle,
  DialogActions,
  GroupsIcon,
  MoreVertIcon,
  AddIcon,
  DataGrid,
  Dialog,
  ExpandMoreIcon,
  AccordionDetails,
  AccordionSummary,
  Accordion,
  PersonIcon,
  validateName,
  OutlineButton,
  validatePassword,
  validateEmail,
  validateConfirmPassword,
  Box,
  FormHelperText,
  TextField,
  Typography,
  FormControlLabel,
  Checkbox,
  Button,
  IconButton,
  InputAdornment,
  OutlinedInput,
  VisibilityOff,
  CrisisAlertIcon,
  Visibility,
  Link,
  List,
  ListItem,
  Svg,
  Png,
  Video,
  Sidebar,
  Header,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TextareaAutosize,
  Select,
  MenuItem,
  FormControl,
  RadioGroup,
  Radio,
  Tabs,
  Tab,
  Switch,
  styled,
  Menu,
  AuthVideo,
  PrimaryButton,
  Grid,
  UserHeader,
  RefferalProgram,
  BlueBorderButton,
  UserSidebar,
  FitrizerCard,
  DataPicker,
  Loader,
  AuthFooter,
  Pdf,
  Pagination,
  EditIcon,
  DeleteIcon,
  Tooltip,
  tooltipClasses,
  KeyboardBackspaceIcon,
  InventorySharpIcon,
  ListItemText,
  SettingsAccessibilityIcon,
  CardGiftcardIcon,
  AddShoppingCartIcon,
  ThumbsUpDownOutlinedIcon,
  ReceiptOutlinedIcon,
  AccountBalanceWalletOutlinedIcon,
  ContactMailOutlinedIcon,
  AssignmentOutlinedIcon,
  SettingsSuggestOutlinedIcon,
  AssessmentOutlinedIcon,
  LiveHelpIcon,
  ReplyIcon,
  CircleNotificationsIcon,
  CardMembershipIcon,
  DinnerDiningIcon,
  RamenDiningIcon,
  FitnessCenterIcon,
  BookOnlineIcon,
  DownloadIcon,
  displayDateValue,
  displayDateWithTime,
  displayNumberValue,
  displayNumberValueWithToFixed,
  displayValue,
  downloadReport,
  getStartAndEndDateByType,
  formatedDateAndTime
};
