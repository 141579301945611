import React from "react";
import Index from "../../../../component/Index";
import { hasActionPermission } from "../../../../common/functions";
import {
  deleteUserPost,
  getPostActiveDeactive,
} from "../../../../redux/features/slices/Admin/AdminService";
import { Pagination, Stack } from "@mui/material";
import PageIndex from "../../../PageIndex";
import AddSponsored from "./AddSponsored";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  boxShadow: 24,
};

const TableUserPost = (props) => {
  const {
    page,
    totalPages,
    handleChange,
    fetchData,
    currentRows,
    searchedData,
    setCurrentRows,
    backendPaginationBoxCss,
    apiData,
    viewSinglePostDetails,
  } = props;

  const [modalOpen, setModalOpen] = PageIndex.useState(false);
  const [postId, setPostId] = PageIndex.useState("");
  const navigate = PageIndex.useNavigate();

  const [openTicketDescription, setOpenTicketDescription] =
    PageIndex.useState(false);
  const [ticketDescription, setTicketDescription] = PageIndex.useState("");
  const [ticketHeading, setTicketHeading] = PageIndex.useState("");
  const [sponsorOpen, setSponsorOpen] = PageIndex.useState(false);
  const [sponsorPost, setSponsorPost] = PageIndex.useState({});

  const { role } = PageIndex.useSelector((store) => store.admin.adminData);
  const permissions = PageIndex.useSelector((store) => store.admin.permissions);

  const handleSponsoredOpen = (data) => {
    setSponsorOpen(true);
    setSponsorPost(data);
  };
  const handleUserClose = () => {
    setSponsorOpen(false);
  };

  PageIndex.useEffect(() => {
    if (!viewSinglePostDetails) fetchData();
  }, []);

  const onCloseModal = () => {
    setModalOpen(false);
  };
  const userPostDelete = () => {
    const data = { id: postId };
    dispatch(deleteUserPost(data)).then((res) => {
      if (res?.payload?.status === 200) {
        PageIndex.toast.success(res?.payload?.message);
        fetchData();
      }
    });
  };
  const handleDelete = () => {
    userPostDelete();
    onCloseModal();
  };
  const onOpenModal = () => {
    setModalOpen(true);
  };
  const handleView = (singlePostData) => {
    navigate("/admin/viewpost", {
      state: { singlePostData: singlePostData },
    });
  };

  const dispatch = PageIndex.useDispatch();
  const singalUserPost = (userID) => {
    if (window.location.href.includes("report") === false) {
      navigate("/admin/singaluserpost", {
        state: { id: userID },
      });
    }
  };
  if (!apiData && !viewSinglePostDetails) {
    return <Index.Loader />;
  }

  // Enable Disable Posts based on Status of the Post
  const handleSwitchActive = (id, status) => {
    const urlEncodedData = new URLSearchParams();
    urlEncodedData.append("id", id);
    urlEncodedData.append("isActive", status);
    dispatch(getPostActiveDeactive(urlEncodedData)).then((response) => {
      const { status } = response?.payload || {};
      if (status === 200) {
        fetchData();
      }
    });
  };

  const handleClickOpenDescription = (heading, description) => {
    setOpenTicketDescription(true);
    setTicketHeading(heading);
    setTicketDescription(description);
  };

  const handleCloseDescription = () => {
    setOpenTicketDescription(false);
    setTicketHeading("");
    setTicketDescription("");
  };

  return (
    <div>
      <Index.Box className="dashboard-content add-user-containt">
        <Index.Box className="admin-dashboard-list-row admin-setup-goal-row">
          <Index.Box sx={{ width: 1 }} className="grid-main">
            <Index.Box
              display="grid"
              className="display-row"
              gridTemplateColumns="repeat(12, 1fr)"
              gap={{ xs: 2, sm: 2, md: 0, lg: 0 }}
            >
              <Index.Box
                gridColumn={{
                  xs: "span 12",
                  sm: "span 12",
                  md: "span 12",
                  lg: "span 12",
                }}
                className="grid-column"
              >
                <Index.Box>
                  <Index.Box className="user-post-table page-table-main">
                    <Index.TableContainer
                      component={Index.Paper}
                      className="table-container table-head-sticky"
                      sx={{ maxHeight: 405 }}
                    >
                      <Index.Table
                        aria-label="simple table"
                        className="table-user-maindata"
                      >
                        <Index.TableHead className="table-head">
                          <Index.TableRow className="table-row">
                            <Index.TableCell
                              component="th"
                              variant="th"
                              className="table-th"
                              align="center"
                            >
                              No.
                            </Index.TableCell>
                            <Index.TableCell
                              component="th"
                              variant="th"
                              className="table-th"
                              align="center"
                            >
                              {props.postTitle}
                            </Index.TableCell>
                            <Index.TableCell
                              component="th"
                              variant="th"
                              className="table-th"
                              align="left"
                            >
                              Created by
                            </Index.TableCell>
                            <Index.TableCell
                              component="th"
                              variant="th"
                              className="table-th"
                              align="left"
                            >
                              {props.optionalLikeLength}
                            </Index.TableCell>
                            <Index.TableCell
                              component="th"
                              variant="th"
                              className="table-th"
                              align="left"
                            >
                              Description
                            </Index.TableCell>
                            <Index.TableCell
                              component="th"
                              variant="th"
                              className="table-th"
                              align="left"
                            >
                              Created At
                            </Index.TableCell>

                            <Index.TableCell
                              component="th"
                              variant="th"
                              className="table-th"
                              align="left"
                            >
                              Status
                            </Index.TableCell>

                            {!viewSinglePostDetails && (
                              <Index.TableCell
                                component="th"
                                variant="th"
                                className="table-th"
                                align="center"
                              >
                                Actions
                              </Index.TableCell>
                            )}
                          </Index.TableRow>
                        </Index.TableHead>
                        <Index.TableBody className="table-body">
                          {/* {currentRows?.length && apiData ? ( */}
                          {currentRows && currentRows?.length > 0 ? (
                            currentRows?.map((data, index) => (
                              <Index.TableRow key={index}>
                                <Index.TableCell
                                  component="td"
                                  variant="td"
                                  scope="row"
                                  className="table-td"
                                >
                                  <Index.Box className="userlist-data anchor-link">
                                    {/* {index + 1} */}
                                    {data?.count ? data?.count : index + 1}
                                  </Index.Box>
                                </Index.TableCell>
                                <Index.TableCell
                                  component="td"
                                  variant="td"
                                  className="table-td"
                                  align="center"
                                >
                                  <img
                                    src={
                                      data.image
                                        ? data.image instanceof Blob
                                          ? URL.createObjectURL(data.image)
                                          : `${PageIndex.ImageURL}${data?.image}`
                                        : Index.Png.herobg
                                    }
                                    alt="loading..."
                                    className="user-post-user-img"
                                  />
                                </Index.TableCell>
                                <Index.TableCell
                                  component="td"
                                  variant="td"
                                  className="table-td"
                                  align="left"
                                  onClick={() =>
                                    singalUserPost(data?.createdBy?._id)
                                  }
                                >
                                  {window?.location?.href.includes("report")
                                    ? data?.postId?.createdBy?.fullName
                                      ? data?.postId?.createdBy?.fullName
                                      : "-"
                                    : data?.createdBy?.fullName
                                    ? data?.createdBy?.fullName
                                    : "-"}
                                </Index.TableCell>
                                <Index.TableCell
                                  component="td"
                                  variant="td"
                                  className="table-td token-tab"
                                  align="left"
                                >
                                  {window.location.href.includes("report")
                                    ? data?.userId?.fullName
                                      ? data?.userId?.fullName
                                      : "-"
                                    : data?.likes?.length
                                    ? data?.likes?.length
                                    : 0}
                                </Index.TableCell>
                                <Index.TableCell
                                  component="td"
                                  variant="td"
                                  scope="row"
                                  className="table-td"
                                  align="left"
                                  onClick={() =>
                                    handleClickOpenDescription(
                                      "Description",
                                      window.location.href.includes("report")
                                        ? data?.report
                                        : data?.description
                                    )
                                  }
                                >
                                  <Index.Box className="userlist-data justify-content-start anchor-link">
                                    {window.location.href.includes("report")
                                      ? data?.report
                                        ? data?.report.slice(0, 20).length > 19
                                          ? data?.report.slice(0, 20) + "..."
                                          : data?.report.slice(0, 20)
                                        : "-"
                                      : data?.description
                                      ? data?.description.slice(0, 20).length >
                                        19
                                        ? data?.description.slice(0, 20) + "..."
                                        : data?.description
                                      : "-"}
                                  </Index.Box>
                                </Index.TableCell>
                                <Index.TableCell
                                  component="td"
                                  variant="td"
                                  scope="row"
                                  className="table-td"
                                  align="left"
                                >
                                  <Index.Box className="userlist-data anchor-link">
                                    {data?.createdAt
                                      ? PageIndex.moment(data.createdAt).format(
                                          "MM/DD/YYYY hh:mm A"
                                        )
                                      : "-"}
                                  </Index.Box>
                                </Index.TableCell>

                                <Index.TableCell
                                  component="td"
                                  variant="td"
                                  scope="row"
                                  className="table-td"
                                >
                                  <Index.Box
                                    className="anchor-link"
                                    style={{
                                      color:
                                        data?.isActive === true
                                          ? "green"
                                          : "red",
                                    }}
                                  >
                                    {data?.isActive === true
                                      ? "Enable"
                                      : "Disable"}
                                  </Index.Box>
                                </Index.TableCell>

                                {!viewSinglePostDetails && (
                                  <Index.TableCell
                                    component="td"
                                    variant="td"
                                    className="table-td"
                                  >
                                    <Index.Box
                                      className="action-btn-icon"
                                      sx={{ display: "flex" }}
                                    >
                                      <Index.Box className="switchcolor">
                                        <Index.Switch
                                          size="small"
                                          checked={data?.isActive}
                                          onClick={() =>
                                            handleSwitchActive(
                                              data?._id,
                                              data?.isActive === true
                                                ? false
                                                : true
                                            )
                                          }
                                        />
                                      </Index.Box>

                                      {/* Mark As Sponsored */}
                                      <Index.Tooltip title="Mark As Sponsored">
                                        <Index.Button
                                          onClick={() =>
                                            handleSponsoredOpen(data)
                                          }
                                          className="save-user-btn primary-btn goal-btn"
                                          variant="contained"
                                        >
                                          <Index.CrisisAlertIcon />
                                        </Index.Button>
                                      </Index.Tooltip>

                                      {/* View */}
                                      <Index.Tooltip title="View details">
                                        <Index.Button
                                          className="admin-menu-btn"
                                          variant="contained"
                                          onClick={() => handleView(data)}
                                        >
                                          <Index.Visibility />
                                        </Index.Button>
                                      </Index.Tooltip>

                                      {/* Delete */}
                                      {hasActionPermission(
                                        role,
                                        permissions,
                                        "userPost",
                                        "Delete"
                                      ) && ( // Check Delete permission
                                        <Index.Tooltip title="Delete">
                                          <Index.Button
                                            className="admin-menu-btn"
                                            variant="contained"
                                            onClick={() => {
                                              setPostId(data._id);
                                              onOpenModal();
                                            }}
                                          >
                                            <Index.DeleteIcon />
                                          </Index.Button>
                                        </Index.Tooltip>
                                      )}
                                    </Index.Box>
                                  </Index.TableCell>
                                )}
                              </Index.TableRow>
                            ))
                          ) : (
                            <Index.TableRow>
                              <Index.TableCell
                                component="td"
                                variant="td"
                                scope="row"
                                className="no-data-in-list"
                                colSpan={6}
                                align="center"
                              >
                                {!apiData ? <Index.Loader /> : "No Data found"}
                              </Index.TableCell>
                            </Index.TableRow>
                          )}
                        </Index.TableBody>
                      </Index.Table>
                    </Index.TableContainer>
                  </Index.Box>

                  {viewSinglePostDetails ? (
                    <Index.Pagination
                      setCurrentRows={setCurrentRows}
                      allData={searchedData}
                      pageData={currentRows}
                    />
                  ) : (
                    <Index.Box className={backendPaginationBoxCss}>
                      <Stack spacing={2}>
                        <Pagination
                          count={totalPages * 1}
                          page={page}
                          onChange={handleChange}
                          variant="outlined"
                          shape="rounded"
                          size="small"
                          className="user-post-pagination"
                        />
                      </Stack>
                    </Index.Box>
                  )}
                  {/* THIS PAGINATION COME FROM BACKEND */}

                  <Index.Box>
                    <PageIndex.DeleteModals
                      modalOpen={modalOpen}
                      onOpenModal={handleDelete}
                      onCloseModal={onCloseModal}
                      title="Are you sure you want to delete this post?"
                    />
                  </Index.Box>
                </Index.Box>
              </Index.Box>
            </Index.Box>
          </Index.Box>
        </Index.Box>
      </Index.Box>

      {/* Social Post description show modal start */}
      <Index.Modal
        open={openTicketDescription}
        onClose={handleCloseDescription}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        className="common-modal"
      >
        <Index.Box
          sx={style}
          className="common-modal-inner add-edit-goal-inner"
        >
          <Index.Box className="comman-modal-body fix-height-modal">
            <Index.Box className="input-box add-user-input ">
              <Index.Box className="form-group custom-group">
                <Index.FormHelperText className="form-lable">
                  {ticketHeading}
                </Index.FormHelperText>
                <Index.Box className="drop-main-comman">
                  <Index.FormHelperText>
                    {ticketDescription}
                  </Index.FormHelperText>
                </Index.Box>
              </Index.Box>
            </Index.Box>
          </Index.Box>
          <Index.Box className="comman-modal-footer comman-btn-main">
            <button onClick={handleCloseDescription} className="primary-btn">
              Cancel
            </button>
          </Index.Box>
        </Index.Box>
      </Index.Modal>
      {/* Ticket description modal end */}

      {/* Sponsered post form */}
      <Index.Modal
        open={sponsorOpen}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        className="common-modal"
      >
        <AddSponsored handleClose={handleUserClose} sponsorPost={sponsorPost} />
      </Index.Modal>
    </div>
  );
};

export default TableUserPost;
