import {
  Box,
  FormHelperText,
  TextField,
  Typography,
  FormControlLabel,
  Checkbox,
  Button,
  IconButton,
  InputAdornment,
  OutlinedInput,
  List,
  ListItem,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TextareaAutosize,
  Select,
  Menu,
  MenuItem,
  FormControl,
  RadioGroup,
  Radio,
  Tabs,
  Tab,
  Switch,
  styled,
  Modal,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TablePagination,
  InputLabel,
  FilledInput,
  Autocomplete,
  Grid,
  Stepper,
  Step,
  StepLabel,
  ListItemAvatar,
  Avatar,
  ListItemText,
} from "@mui/material";

import { DatePicker } from "@mui/x-date-pickers";
import ArrowForwardIosSharpIcon from "@mui/icons-material/ArrowForwardIosSharp";
import MuiAccordion from "@mui/material/Accordion";
import MuiAccordionSummary from "@mui/material/AccordionSummary";
import MuiAccordionDetails from "@mui/material/AccordionDetails";
import { Link } from "react-router-dom";
import Visibility from "@mui/icons-material/Visibility";
import CheckIcon from "@mui/icons-material/Check";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import Svg from "../assets/Svg";
import Png from "../assets/Png";
import Jpg from "../assets/Jpg";
import Video from "../assets/Video";
import Header from "../component/defaulLayout/user/Header";
import Footer from "../component/defaulLayout/user/Footer";
// import DeleteModals from "../component/modal/DeleteModal";
import PrimaryButton from "../component/common/PrimaryButton";
// import OwlCarousel from "react-owl-carousel3";
import Countdown from "react-countdown";
import GoToTop from "../component/defaulLayout/user/GoToTop";
import Loader from "../component/common/Loader";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';

// container index

import Home from "../component/user/pages/home/Home";
import OutlineButton from "../component/common/OutlineButton";
import JoyfulInvestment from "../component/user/pages/home/JoyfulInvestment";
import Roadmap from "../component/user/pages/home/Roadmap";
import Referral from "../component/user/pages/home/Referral";
import UserHeader from "../component/user/defaulLayout/UserHeader";
import RefferalProgram from "../component/user/pages/userDashboard/RefferalProgram";
import BlueBorderButton from "../component/common/BlueBorderButton";
import UserSidebar from "../component/user/defaulLayout/UserSidebar";
import FitrizerCard from "../component/user/pages/userDashboard/FitrizerCard";
import DataPicker from "../component/user/pages/userDashboard/DataPicker";
import profileWarningModal from "../component/modal/ProfileWarningModal";
import AuthFooter from "../component/defaulLayout/user/AuthFooter";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import ThumbUpIcon from "@mui/icons-material/ThumbUp";
import { CircularProgress } from "@mui/material";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import CloseIcon from "@mui/icons-material/Close";

export default {
  ThumbUpIcon,
  LocalizationProvider,
  // DeleteModals,
  MoreVertIcon,
  CircularProgress,
  Svg,
  Png,
  Jpg,
  Link,
  Button,
  Typography,
  Home,
  Header,
  List,
  ListItem,
  PrimaryButton,
  OutlineButton,
  JoyfulInvestment,
  Roadmap,
  Referral,
  Footer,
  TextField,
  TextareaAutosize,
  Checkbox,
  Countdown,
  // OwlCarousel,
  Box,
  FormHelperText,
  FormControlLabel,
  IconButton,
  InputAdornment,
  OutlinedInput,
  VisibilityOff,
  Visibility,
  CheckIcon,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Select,
  Menu,
  MenuItem,
  FormControl,
  RadioGroup,
  Radio,
  Tabs,
  Tab,
  Switch,
  styled,
  Modal,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  ArrowForwardIosSharpIcon,
  MuiAccordion,
  MuiAccordionSummary,
  MuiAccordionDetails,
  InputLabel,
  FilledInput,
  Video,
  TablePagination,
  GoToTop,
  Grid,
  UserHeader,
  RefferalProgram,
  Autocomplete,
  BlueBorderButton,
  UserSidebar,
  Stepper,
  Step,
  StepLabel,
  FitrizerCard,
  DataPicker,
  profileWarningModal,
  Loader,
  AuthFooter,
  EditIcon,
  DeleteIcon,
  KeyboardBackspaceIcon,  
  ListItemAvatar,
  Avatar,
  ListItemText,
  CloseIcon,
  DatePicker
};
