import React, { useState } from "react";
import { useLocation } from "react-router-dom";
import Index from "../../Index";
import PageIndex from "../../../container/PageIndex";
import AdminMenus from "./AdminMenus";
import SubAdminMenus from "./SubAdminMenus";

export default function Sidebar() {
  const pathname = useLocation();
  const [openUserManagement, setOpenUserManagement] = useState(false);
  const [openMasterRecord, setOpenMasterRecord] = useState(false);
  const [openNFT, setOpenNFT] = useState(false);
  const [openPosts, setOpenPosts] = useState(false);
  const [openIndividualChallenges, setOpenIndividualChallenges] = useState(false);
  const [openOrders, setOpenOrders] = useState(false);
  const [openTrainer, setOpenTrainer] = useState(false);
  const [openReviews, setOpenReviews] = useState(false);
  const [openInventory, setOpenInventory] = useState(false);
  const [openCMS, setOpenCMS] = useState(false);
  const [openDropdownIndex, setOpenDropdownIndex] = useState(null);

  const { role } = PageIndex.useSelector((store) => store.admin.adminData);
  const permissions = PageIndex.useSelector((store) => store.admin.permissions);
  const isSuperAdmin = role === "admin";

  const handleOpenCms = (index) => {
    setOpenDropdownIndex(index); // Open the clicked dropdown
    // Based on the index, open the corresponding dropdown and close others
    if (index === 1) {
      setOpenUserManagement(true);
      setOpenMasterRecord(false);
      setOpenNFT(false);
      setOpenPosts(false);
      setOpenIndividualChallenges(false);
      setOpenOrders(false);
      setOpenTrainer(false);
      setOpenReviews(false);
      setOpenInventory(false);
      setOpenCMS(false);
    } else if (index === 2) {
      setOpenMasterRecord(true);
      setOpenUserManagement(false);
      setOpenNFT(false);
      setOpenPosts(false);
      setOpenIndividualChallenges(false);
      setOpenOrders(false);
      setOpenTrainer(false);
      setOpenReviews(false);
      setOpenInventory(false);
      setOpenCMS(false);
    } else if (index === 3) {
      setOpenNFT(true);
      setOpenInventory(false);
      setOpenUserManagement(false);
      setOpenPosts(false);
      setOpenOrders(false);
      setOpenMasterRecord(false);
      setOpenTrainer(false);
      setOpenReviews(false);
      setOpenIndividualChallenges(false);
      setOpenCMS(false);
    } else if (index === 4) {
      setOpenPosts(true);
      setOpenUserManagement(false);
      setOpenMasterRecord(false);
      setOpenIndividualChallenges(false);
      setOpenNFT(false);
      setOpenTrainer(false);
      setOpenReviews(false);
      setOpenOrders(false);
      setOpenCMS(false);
      setOpenInventory(false);
    } else if (index === 5) {
      setOpenIndividualChallenges(true);
      setOpenPosts(false);
      setOpenUserManagement(false);
      setOpenMasterRecord(false);
      setOpenNFT(false);
      setOpenOrders(false);
      setOpenTrainer(false);
      setOpenReviews(false);
      setOpenCMS(false);
      setOpenInventory(false);
    } else if (index === 6) {
      setOpenOrders(true);
      setOpenPosts(false);
      setOpenUserManagement(false);
      setOpenMasterRecord(false);
      setOpenIndividualChallenges(false);
      setOpenInventory(false);
      setOpenTrainer(false);
      setOpenReviews(false);
      setOpenNFT(false);
      setOpenCMS(false);
    } else if (index === 7) {
      setOpenInventory(true);
      setOpenOrders(false);
      setOpenPosts(false);
      setOpenUserManagement(false);
      setOpenMasterRecord(false);
      setOpenIndividualChallenges(false);
      setOpenTrainer(false);
      setOpenReviews(false);
      setOpenNFT(false);
      setOpenCMS(false);
    } else if (index === 8) {
      setOpenTrainer(true);
      setOpenOrders(false);
      setOpenPosts(false);
      setOpenUserManagement(false);
      setOpenInventory(false);
      setOpenMasterRecord(false);
      setOpenIndividualChallenges(false);
      setOpenReviews(false);
      setOpenNFT(false);
      setOpenCMS(false);
    } else if (index === 9) {
      setOpenCMS(true);
      setOpenTrainer(false);
      setOpenReviews(false);
      setOpenOrders(false);
      setOpenPosts(false);
      setOpenUserManagement(false);
      setOpenInventory(false);
      setOpenMasterRecord(false);
      setOpenIndividualChallenges(false);
      setOpenNFT(false);
    } else if (index === 10) {
      setOpenReviews(true);
      setOpenCMS(false);
      setOpenTrainer(false);
      setOpenOrders(false);
      setOpenPosts(false);
      setOpenUserManagement(false);
      setOpenInventory(false);
      setOpenMasterRecord(false);
      setOpenIndividualChallenges(false);
      setOpenNFT(false);
    } else {
      setOpenUserManagement(false);
      setOpenMasterRecord(false);
      setOpenNFT(false);
      setOpenPosts(false);
      setOpenTrainer(false);
      setOpenReviews(false);
      setOpenIndividualChallenges(false);
      setOpenInventory(false);
      setOpenCMS(false);
    }
  };

  const removeClass = () => {
    var element = document.getElementById("admin-sidebar");
    element.classList.remove("active");
    var body = document.getElementsByTagName("body");
    body[0].classList.remove("body-overflow");
    setOpenUserManagement(false);
    setOpenMasterRecord(false);
    setOpenNFT(false);
    setOpenPosts(false);
    setOpenIndividualChallenges(false);
    setOpenOrders(false);
    setOpenTrainer(false);
    setOpenInventory(false);
    setOpenCMS(false);
  };

  const hasPermission = (module, permissionType) => {
    return (permissions && permissions[module]?.[permissionType]) || false;
  };

  // Check if any child items have permission to be shown
  const shouldShowUserManagement =
    hasPermission("user", "View") ||
    hasPermission("trainerList", "View") ||
    hasPermission("subAdmins", "View");

  const shouldShowMasterRecords =
    hasPermission("goalSetup", "View") ||
    hasPermission("nftSetup", "View") ||
    hasPermission("nftRepairSetup", "View") ||
    hasPermission("recipe", "View") ||
    hasPermission("suppliers", "View") ||
    hasPermission("warehouse", "View") ||
    hasPermission("store", "View") ||
    hasPermission("coupons", "View") ||
    hasPermission("centerTypes", "View") ||
    hasPermission("amenities", "View") ||
    hasPermission("equipments", "View") ||
    hasPermission("services", "View");

  const shouldShowNFTMenu =
    hasPermission("nftListing", "View") ||
    hasPermission("leaseNFT", "View") ||
    hasPermission("repairNFTRequest", "View") ||
    hasPermission("nftTransactions", "View");

  const shouldShowPosts =
    hasPermission("userPost", "View") ||
    hasPermission("reportPost", "View") ||
    hasPermission("reportUser", "View") ||
    hasPermission("sponsoredPosts", "View");

  const shouldShowOrders =
    hasPermission("orders", "View") ||
    hasPermission("orderRating", "View") ||
    hasPermission("orderTickets", "View");

  const shouldShowInventory =
    hasPermission("categories", "View") ||
    hasPermission("subCategories", "View") ||
    hasPermission("brands", "View") ||
    hasPermission("products", "View") ||
    hasPermission("trainerProducts", "View") ||
    hasPermission("stockReport", "View") ||
    hasPermission("salesReport", "View");

  const shouldShowTrainer =
    hasPermission("appointmentList", "View") ||
    hasPermission("sessionList", "View") ||
    hasPermission("nutritionPlans", "View") ||
    hasPermission("workoutPlans", "View") ||
    hasPermission("subscriptionList", "View");

  const shouldShowReviews = 
    hasPermission("orderReview", "View") ||
    hasPermission("centerReview", "View") ||
    hasPermission("trainerReview", "View");

  const shouldShowCMS =
    hasPermission("aboutUs", "View") ||
    hasPermission("privacyPolicy", "View") ||
    hasPermission("termsConditions", "View") ||
    hasPermission("faq", "View") ||
    hasPermission("tickets", "View") ||
    hasPermission("contactRequest", "View");

  return (
    <>
      <Index.Box className="admin-sidebar-main scrollbar" id={"admin-sidebar"}>
        <Index.Box className="admin-sidebar-inner-main">
          <Index.Box className="admin-sidebar-logo-main">
            <img
              src={Index.Png.lightLogo}
              className="admin-sidebar-logo"
              alt="logo"
            />
            <Index.Button
              onClick={removeClass}
              className="cancel-mobile-menu-icon"
            >
              <img
                src={Index.Png.cancelicon}
                className="admin-sidebar-close"
                alt="logo"
              />
            </Index.Button>
          </Index.Box>
          <Index.Box className="bottom-border"></Index.Box>

          <Index.Box className="admin-sidebar-list-main">
            {isSuperAdmin ? ( // Super Admin Login Then show menu
              <AdminMenus
                pathname={pathname}
                removeClass={removeClass}
                openUserManagement={openUserManagement}
                openMasterRecord={openMasterRecord}
                openNFT={openNFT}
                openPosts={openPosts}
                openIndividualChallenges={openIndividualChallenges}
                openOrders={openOrders}
                openInventory={openInventory}
                openTrainer={openTrainer}
                openReviews={openReviews}
                openCMS={openCMS}
                openDropdownIndex={openDropdownIndex}
                handleOpenCms={handleOpenCms}
              />
            ) : (
              // Sub Admin login then show this menus
              <SubAdminMenus
                pathname={pathname}
                removeClass={removeClass}
                openUserManagement={openUserManagement}
                openMasterRecord={openMasterRecord}
                openNFT={openNFT}
                openPosts={openPosts}
                openIndividualChallenges={openIndividualChallenges}
                openOrders={openOrders}
                openInventory={openInventory}
                openTrainer={openTrainer}
                openReviews={openReviews}
                openCMS={openCMS}
                openDropdownIndex={openDropdownIndex}
                shouldShowCMS={shouldShowCMS}
                shouldShowTrainer={shouldShowTrainer}
                shouldShowReviews={shouldShowReviews}
                shouldShowInventory={shouldShowInventory}
                shouldShowOrders={shouldShowOrders}
                shouldShowPosts={shouldShowPosts}
                shouldShowNFTMenu={shouldShowNFTMenu}
                shouldShowMasterRecords={shouldShowMasterRecords}
                shouldShowUserManagement={shouldShowUserManagement}
                handleOpenCms={handleOpenCms}
                hasPermission={hasPermission}
              />
            )}
          </Index.Box>
        </Index.Box>
      </Index.Box>
    </>
  );
}
