import React from "react";
// import Index from "../../../Index";
import Index from "../../../../component/Index";
import PageIndex from "../../../PageIndex";
import { addAsSponsoredData } from "../../../../redux/features/slices/Admin/AdminService";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  boxShadow: 24,
};

const AddSponsored = ({ handleClose, sponsorPost }) => {
  const dispatch = PageIndex.useDispatch();
  const [loading, setLoading] = PageIndex.useState(false);
  let initialValues = {
    postId: sponsorPost?._id,
    startDate: "",
    endDate: "",
  };
  const onKeyDown = (e) => {
    e.preventDefault();
  };

  const handleFormSubmit = async (values) => {
    setLoading(true);
    const formData = new URLSearchParams();
    formData.append("postId", sponsorPost?._id);
    formData.append("startDate", values.startDate);
    formData.append("endDate", values.endDate);
    dispatch(addAsSponsoredData(formData)).then((res) => {
      if (res?.payload?.status === 201 || res?.payload?.status === 200) {
        setLoading(false);
        handleClose();
      } else {
        setLoading(false);
      }
    });
  };

  return (
    <Index.Box sx={style} className="common-modal-inner add-edit-goal-inner">
      <PageIndex.DynamicTitle title="Fitrizer - Add User" />
      <PageIndex.Formik
        initialValues={initialValues}
        validationSchema={PageIndex.addSponsoredByValidationSchema}
        onSubmit={handleFormSubmit}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          setFieldValue,
        }) => (
          <Index.Box>
            <form onSubmit={handleSubmit}>
              <div className="add-edit-goal-form">
                <Index.Box className="comman-modal-header">
                  <h2>Sponsored</h2>
                </Index.Box>

                <Index.Box className="comman-modal-body fix-height-modal">
                  <Index.Box className="input-box add-user-input">
                    <Index.FormHelperText className="form-lable">
                      Start Date <span class="text-danger">*</span>
                    </Index.FormHelperText>
                    <Index.Box className="datepicker-wrap">
                      <Index.LocalizationProvider
                        dateAdapter={Index.AdapterMoment}
                        className="datepicker-wrap-inner"
                      >
                        <Index.DatePicker
                          inputFormat="MM/DD/YYYY"
                          disablePast="true"
                          value={values?.startDate ? values?.startDate : ""}
                          onChange={(value) => {
                            setFieldValue(
                              "startDate",
                              PageIndex?.moment(value?._d)
                            );
                          }}
                          renderInput={(props) => (
                            <Index.TextField
                              {...props}
                              size="small"
                              onKeyDown={onKeyDown}
                              helperText={null}
                              className="date-text-list"
                              style={{ caretColor: "transparent" }}
                            />
                          )}
                          className="mob-date-picker date-picker-details"
                        />
                      </Index.LocalizationProvider>
                    </Index.Box>
                    {touched.startDate && errors.startDate && (
                      <Index.FormHelperText error>
                        {errors.startDate}
                      </Index.FormHelperText>
                    )}
                  </Index.Box>
                </Index.Box>

                <Index.Box className="comman-modal-body fix-height-modal">
                  <Index.Box className="input-box add-user-input">
                    <Index.FormHelperText className="form-lable">
                      End Date <span class="text-danger">*</span>
                    </Index.FormHelperText>
                    <Index.Box className="datepicker-wrap">
                      <Index.LocalizationProvider
                        dateAdapter={Index.AdapterMoment}
                        className="datepicker-wrap-inner"
                      >
                        <Index.DatePicker
                          inputFormat="MM/DD/YYYY"
                          minDate={values?.startDate}
                          value={values?.endDate ? values?.endDate : ""}
                          onChange={(value) => {
                            setFieldValue(
                              "endDate",
                              PageIndex?.moment(value?._d)
                            );
                          }}
                          renderInput={(props) => (
                            <Index.TextField
                              {...props}
                              size="small"
                              onKeyDown={onKeyDown}
                              helperText={null}
                              className="date-text-list"
                              style={{ caretColor: "transparent" }}
                            />
                          )}
                          className="mob-date-picker date-picker-details"
                        />
                      </Index.LocalizationProvider>
                    </Index.Box>
                    {touched.endDate && errors.endDate && (
                      <Index.FormHelperText error>
                        {errors.endDate}
                      </Index.FormHelperText>
                    )}
                  </Index.Box>
                </Index.Box>

                <Index.Box className="comman-modal-footer comman-btn-main">
                  <Index.Button
                    type="submit"
                    className="primary-btn"
                    startIcon={
                      loading && (
                        <Index.CircularProgress
                          sx={{ color: "white" }}
                          size={15}
                        />
                      )
                    }
                    disabled={loading}
                  >
                    Mark Sponsore
                  </Index.Button>
                  <Index.Button onClick={handleClose} className="primary-btn">
                    Cancel
                  </Index.Button>
                </Index.Box>
              </div>
            </form>
          </Index.Box>
        )}
      </PageIndex.Formik>
    </Index.Box>
  );
};

export default AddSponsored;
