import { createAsyncThunk } from "@reduxjs/toolkit";
import { DataService } from "../../../../config/DataService";
import { API } from "../../../../config/Api";
import { toast } from "react-toastify";

export const LoginAdmin = createAsyncThunk(
  "users/loginAdmin",
  async (userData) => {
    try {
      const response = await DataService.post(API.Admin.ADMIN_LOGIN, userData);
      return response.data;
    } catch (error) {
      toast.error(error?.response?.data?.message);
    }
  }
);

export const registerAdmin = createAsyncThunk(
  "users/registerAdmin",
  async (userData) => {
    const response = await DataService.post(API.Admin.USER_REGISTER, userData);
    return response.data;
  }
);

export const forgotPasswordAdmin = createAsyncThunk(
  "admin/forgotPasswordAdmin",
  async (data) => {
    try {
      const response = await DataService.post(API.Admin.FORGOT_PASSWORD, data);
      return response?.data;
    } catch (error) {
      toast.error(error?.response?.data?.message);
    }
  }
);

export const ResetPasswordAPI = createAsyncThunk(
  "admin/resetPassword",

  async (userData) => {
    try {
      const response = await DataService.post(
        API.Admin.RESET_PASSWORD,
        userData
      );
      return response.data;
    } catch (error) {
      toast.error(error.response.data.message);
    }
  }
);

export const ResetPasswordResendOTPAPI = createAsyncThunk(
  "admin/resetPasswordResendOTP",

  async (userData) => {
    try {
      const response = await DataService.post(
        API.Admin.RESEND_OTP_RESET_PASSWORD,
        userData
      );
      return response.data;
    } catch (error) {
      toast.error(error.response.data.message);
    }
  }
);

export const getUser = createAsyncThunk("users/getUser", async () => {
  const response = await DataService.post(API.Admin.GET_USER_LIST);
  return response.data;
});

export const getAdminWallet = createAsyncThunk("admin/getWallet", async () => {
  try {
    const response = await DataService.get(API.Admin.GET_WALLET);
    return response.data;
  } catch (error) {}
});

export const getUserPost = createAsyncThunk("getUserPost", async (page) => {
  try {
    const response = await DataService.get(
      `${API.Admin.GET_USER_POST}?page=${page}`
    );
    return response.data;
  } catch (error) {}
});

export const getUserReportPost = createAsyncThunk(
  "admin/getAllReport",
  async () => {
    try {
      const response = await DataService.get(API.Admin.GET_ALLREPORT);
      return response.data;
    } catch (error) {}
  }
);

export const getUserReportUser = createAsyncThunk(
  "admin/getAllReport",
  async () => {
    const data = "?report=allUserReport";
    try {
      const response = await DataService.get(
        `${API.Admin.GET_USER_ALL_REPORT}${data}`
      );
      return response.data;
    } catch (error) {}
  }
);

export const getSingalUserReport = createAsyncThunk(
  "admin/getAllReport",
  async (data) => {
    try {
      const response = await DataService.post(
        API.Admin.GET_SINGLE_USER_REPORT,
        data
      );
      return response.data;
    } catch (error) {}
  }
);

export const getAdminTransaction = createAsyncThunk(
  "admin/getTransaction",
  async () => {
    try {
      const response = await DataService.get(API.Admin.GET_ALL_TRANSACTION);
      return response.data;
    } catch (error) {}
  }
);

export const getContactRequest = createAsyncThunk(
  "admin/getContactRequest",
  async () => {
    try {
      const response = await DataService.get(API.Admin.GET_CONTACT_REQUEST);
      return response.data;
    } catch (error) {
      toast.error(error.response.data.message);
    }
  }
);

export const getGeneralSetting = createAsyncThunk(
  "admin/getGeneralSetting",
  async () => {
    try {
      const response = await DataService.get(API.Admin.GET_GENERAL_SETTING);
      return response.data;
    } catch (error) {
      toast.error(error.response.data.message);
    }
  }
);

export const removeRoadList = createAsyncThunk(
  "admin/removeRoadList",
  async (data) => {
    try {
      const response = await DataService.get(
        `${API.Admin.REMOVE_ROAD_LIST}/${data.id}`
      );
      toast.success(response?.data?.message);
      return response.data;
    } catch (error) {
      toast.error(error?.message);
    }
  }
);

export const adminRoadListData = createAsyncThunk(
  "admin/adminRoadListData",
  async (data) => {
    try {
      const response = await DataService.post(
        API.Admin.ROADMAP_LIST,
        data.formData
      );
      return response.data;
    } catch (error) {
      // toast.error(error?.message)
    }
  }
);

export const postAllReportOfSpecificpost = createAsyncThunk(
  "admin/allreportofspecificpost",
  async (data) => {
    try {
      const response = await DataService.post(
        API.Admin.POST_ALL_REPORT_SPECIFIC_POST,
        data
      );
      return response.data;
    } catch (error) {
      // toast.error(error?.message)
    }
  }
);

export const postSingleReport = createAsyncThunk(
  "admin/allreportofspecificpost",
  async (data) => {
    try {
      const response = await DataService.post(
        API.Admin.POST_SINGLE_REPORT,
        data
      );
      return response.data;
    } catch (error) {
      // toast.error(error?.message)
    }
  }
);

export const getAllUserHomePage = createAsyncThunk(
  "admin/getAllUserHomePage",
  async () => {
    try {
      const response = await DataService.get(API.Admin.GET_ALL_USER_COUNT);
      return response.data;
    } catch (error) {
      // toast.error(error?.message)
    }
  }
);

export const adminProfileData = createAsyncThunk(
  "admin/adminProfileData",
  async () => {
    try {
      const response = await DataService.get(API.Admin.PROFILE_DATA);
      return response.data;
    } catch (error) {
      // toast.error(error?.message)
    }
  }
);

export const adminEditProfileData = createAsyncThunk(
  "admin/adminEditProfileData",
  async (data) => {
    try {
      const response = await DataService.post(
        API.Admin.EDIT_PROFILE,
        data.values
      );
      toast.success(response?.data?.message);
      return response.data;
    } catch (error) {
      toast.error(error?.response?.data?.message);
    }
  }
);

export const addEditRoadMap = createAsyncThunk(
  "admin/addEditRoadMap",
  async (data) => {
    try {
      const response = await DataService.post(
        API.Admin.ROAD_ADD_EDIT,
        data.formData
      );
      toast.success(response?.data?.message);
      return response.data;
    } catch (error) {
      toast.error(error?.message);
    }
  }
);

export const addEditGeneralSetting = createAsyncThunk(
  "admin/addEditGeneralSetting",
  async (data) => {
    try {
      const response = await DataService.post(
        API.Admin.ADD_EDIT_GENERAL_SETTING,
        data
      );
      // toast.success(response?.data?.message)
      return response.data;
    } catch (error) {
      toast.error(error?.response?.data?.message);
    }
  }
);

export const postSingleGroupDetail = createAsyncThunk(
  "admin/postSingleGroupDetail",
  async (data) => {
    try {
      const res = await DataService.post(API.Admin.POST_SINGLE_GROUP, data);
      return res.data;
    } catch (error) {
      toast.error(error?.response?.data?.message);
    }
  }
);

export const deleteGroup = createAsyncThunk(
  "admin/deleteGroup",
  async (data) => {
    try {
      const res = await DataService.post(API.Admin.DELETE_GROUP, data);
      return res.data;
    } catch (error) {
      toast.error(error?.response?.data?.message);
    }
  }
);

export const postClientDetail = createAsyncThunk(
  "admin/deleteGroup",
  async (data) => {
    try {
      const res = await DataService.post(API.Admin.POST_CLIENT_LIST_DATA, data);
      return res.data;
    } catch {}
  }
);

export const getCenterDetail = createAsyncThunk("admin/getCenterDetail", async (ids) => {
    try {
      const res = await DataService.get(`${API.Admin.GET_CENTER_LIST_DATA}?trainerId=${ids}`);
      return res.data;
    } catch {}
  }
);

export const postProductDetail = createAsyncThunk(
  "admin/deleteGroup",
  async (data) => {
    try {
      const res = await DataService.post(API.Admin.POST_PROD_LIST_DATA, data);
      return res.data;
    } catch (error) {}
  }
);

export const adminGetUserList = createAsyncThunk(
  "admin/adminGetUserList",
  async () => {
    try {
      const response = await DataService.get(API.Admin.GET_USER_LIST);
      return response.data;
    } catch (error) {
      toast.error(error.response.data.message);
    }
  }
);

export const adminGetAllGroup = createAsyncThunk(
  "admin/adminGetAllUser",
  async () => {
    try {
      const response = await DataService.get(API.Admin.GET_ALL_GROUP);
      return response.data;
    } catch (error) {
      toast.error(error.response.data.message);
    }
  }
);

export const adminGetAllChallanges = createAsyncThunk(
  "admin/adminGetAllUser",
  async (data) => {
    try {
      const response = await DataService.get(
        `${API.Admin.ADMIN_GET_ALL_CHALLENGES}`
      );
      return response.data;
    } catch (error) {
      // toast.error(error.response.data.message);
    }
  }
);

export const getReferralSignUpUser = createAsyncThunk(
  "admin/getReferralSignUpUser",
  async () => {
    try {
      const response = await DataService.get(
        API.Admin.GET_REFERRED_SIGNUP_USERS
      );
      return response.data;
    } catch (error) {
      toast.error(error.response.data.message);
    }
  }
);

export const getUserList = createAsyncThunk(
  "admin/getUserList",
  async (data) => {
    try {
      const response = await DataService.get(
        `${API.Admin.GET_USER_LIST}${data}`
      );
      return response.data;
    } catch (error) {
      toast.error(error.response.data.message);
    }
  }
);

export const getGoelSetupList = createAsyncThunk(
  "admin/getGoelSetupList",
  async (data) => {
    try {
      const response = await DataService.get(`${API.Admin.GET_GOALS}${data}`);
      return response.data;
    } catch {}
  }
);

export const getNFTSetupListByAdmin = createAsyncThunk(
  "admin/getNFTSetupListByAdmin",
  async (data) => {
    try {
      const response = await DataService.get(
        `${API.Admin.GET_NFT_LIST}${data}`
      );
      return response.data;
    } catch (error) {
      // toast.error(error.response.data.message);
    }
  }
);

export const getTrainerList = createAsyncThunk(
  "admin/getTrainerList",
  async (data) => {
    try {
      const response = await DataService.get(
        `${API.Admin.ADMIN_GET_TRAINER_LIST}${data}`
      );
      return response.data;
    } catch {}
  }
);

export const getGoelColoriesList = createAsyncThunk(
  "admin/getGoelSetupList",
  async (data) => {
    try {
      const response = await DataService.get(`${API.Admin.GET_GOALS}${data}`);
      return response.data;
    } catch (error) {
      // toast.error(error.response.data.message);
    }
  }
);

export const getNFTListByUser = createAsyncThunk(
  "admin/getGoelSetupList",
  async (data) => {
    try {
      const response = await DataService.get(
        `${API.Admin.GET_NFT_LIST}${data}`
      );
      return response.data;
    } catch (error) {
      // toast.error(error.response.data.message);
    }
  }
);

export const getUserGoalSetupList = createAsyncThunk(
  "admin/getGoelSetupList",
  async () => {
    try {
      const response = await DataService.get(API.Admin.GET_SET_GOAL_USER);
      return response.data;
    } catch (error) {
      toast.error(error.response.data.message);
    }
  }
);

export const addEditUserWallet = createAsyncThunk(
  "admin/addEditUserWallet",

  async (data) => {
    try {
      const response = await DataService.post(
        API.Admin.ADD_EDIT_USER_WALLET,
        data
      );
      toast.success(response?.data?.message);
      return response.data;
    } catch (error) {
      toast.error(error?.response?.data?.message);
    }
  }
);

export const getEditUserData = createAsyncThunk(
  "admin/getEditUserData",

  async (data) => {
    try {
      const response = await DataService.post(
        API.Admin.GET_USER_EDIT_DATA,
        data,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      toast.success(response?.data?.message);
      return response.data;
    } catch (error) {
      toast.error(error?.response?.data?.message);
    }
  }
);
export const adminRemoveUser = createAsyncThunk(
  "admin/adminRemoveUser",
  async (data) => {
    try {
      const response = await DataService.get(
        `${API.Admin.USER_REMOVE}/${data}`
      );
      toast.success(response?.data?.message);
      return response.data;
    } catch (error) {
      toast.error(error?.response?.data?.message);
    }
  }
);

export const getGoelActive = createAsyncThunk(
  "admin/adminRemoveUser",
  async (data) => {
    try {
      const response = await DataService.post(API.Admin.GET_GOALS_ACTIVE, data);
      toast.success(response?.data?.message);
      return response.data;
    } catch (error) {
      toast.error(error?.response?.data?.message);
    }
  }
);

export const addSetGoalPost = createAsyncThunk(
  "admin/addSetGoal",
  async (data) => {
    try {
      const response = await DataService.post(API.Admin.ADD_SET_GOEL, data, {
        headers: {
          auth: data.token,
        },
      });
      toast.success(response?.data?.message);
      return response;
    } catch (error) {
      toast.error(error?.response?.data?.message);
    }
  }
);

export const addEditNFTPost = createAsyncThunk(
  "admin/addEditNFTPost",
  async (data) => {
    try {
      const response = await DataService.post(API.Admin.ADD_EDIT_NFT, data);
      toast.success(response?.data?.message);
      return response;
    } catch (error) {
      toast.error(error?.response?.data?.message);
    }
  }
);

export const addNftPostData = createAsyncThunk(
  "admin/addNftPostData",
  async (data) => {
    try {
      const response = await DataService.post(API.Admin.ADD_NFT_Data, data);
      toast.success(response?.data?.message);
      return response;
    } catch (error) {
      toast.error(error?.response?.data?.message);
    }
  }
);
export const editNftPostData = createAsyncThunk(
  "admin/addNftPostData",
  async (data) => {
    try {
      const response = await DataService.post(API.Admin.EDIT_SINGLE_NFT, data);
      toast.success(response?.data?.message);
      return response;
    } catch (error) {
      toast.error(error?.response?.data?.message);
    }
  }
);

export const addEditSingleNFTPost = createAsyncThunk(
  "admin/addEditSingleNFTPost",
  async (data) => {
    try {
      const response = await DataService.post(API.Admin.EDIT_SINGLE_NFT, data);
      toast.success(response?.data?.message);
      return response;
    } catch (error) {
      toast.error(error?.response?.data?.message);
    }
  }
);

export const addNftStackData = createAsyncThunk(
  "admin/addEditSingleNFTPost",
  async (data) => {
    try {
      const response = await DataService.post(
        API.Admin.ADD_SINGLE_NFT_STACK,
        data
      );
      toast.success(response?.data?.message);
      return response;
    } catch (error) {
      toast.error(error?.response?.data?.message);
    }
  }
);
export const updateNftStake = createAsyncThunk(
  "admin/addEditSingleNFTPost",
  async (data) => {
    try {
      const response = await DataService.post(
        API.Admin.EDIT_SINGLE_NFT_STACK,
        data
      );
      toast.success(response?.data?.message);
      return response;
    } catch (error) {
      toast.error(error?.response?.data?.message);
    }
  }
);

export const adminChangePassword = createAsyncThunk(
  "admin/adminChangePassword",
  async (data) => {
    try {
      const response = await DataService.post(
        API.Admin.CHANGE_PASSWORD,
        data.formData
      );
      return response.data;
    } catch (error) {
      toast.error(error?.response?.data?.message);
    }
  }
);

export const addWarehouse = createAsyncThunk(
  "admin/addWarehouse",
  async (data) => {
    try {
      const response = await DataService.post(API.Admin.ADD_WAREHOUSE, data);
      return response.data;
    } catch (error) {
      toast.error(error?.response?.data?.message);
    }
  }
);

export const getSingleWarehouse = createAsyncThunk(
  "admin/getSingleWarehouse",
  async (data) => {
    try {
      const response = await DataService.post(
        API.Admin.GET_SINGLE_WAREHOUSE,
        data
      );
      return response.data;
    } catch (error) {
      toast.error(error?.response?.data?.message);
    }
  }
);

export const editWarehouse = createAsyncThunk(
  "admin/editWarehouse",
  async (data) => {
    try {
      const response = await DataService.post(API.Admin.EDIT_WAREHOUSE, data);
      return response.data;
    } catch (error) {
      toast.error(error?.response?.data?.message);
    }
  }
);

export const deleteWarehouse = createAsyncThunk(
  "admin/deleteWarehouse",
  async (data) => {
    try {
      const response = await DataService.post(API.Admin.DELETE_WAREHOUSE, data);
      return response.data;
    } catch (error) {
      toast.error(error?.response?.data?.message);
    }
  }
);

export const deleteUserPost = createAsyncThunk(
  "admin/deleteUserPost",
  async (data) => {
    try {
      const response = await DataService.post(
        `${API.Admin.DELETE_USER_POST}`,
        data
      );
      return response.data;
    } catch (error) {
      toast.error(error?.response?.data?.message);
    }
  }
);

export const deleteNft = createAsyncThunk(
  "admin/deleteWarehouse",
  async (data) => {
    try {
      const response = await DataService.post(API.Admin.DELETE_NFT_DATA, data);
      return response.data;
    } catch (error) {
      toast.error(error?.response?.data?.message);
    }
  }
);
export const deleteNFTPost = createAsyncThunk(
  "admin/deleteWarehouse",
  async (data) => {
    try {
      const response = await DataService.post(API.Admin.DELETE_NFT_DATA, data);
      return response.data;
    } catch (error) {
      toast.error(error?.response?.data?.message);
    }
  }
);

export const getSingalUserPost = createAsyncThunk(
  "admin/addCategory",
  async (data) => {
    try {
      const response = await DataService.post(
        API.Admin.POST_SINGAL_USER_POST,
        data
      );
      return response.data;
    } catch (error) {
      toast.error(error?.response?.data?.message);
    }
  }
);

export const getStateByCountry = createAsyncThunk(
  "admin/getStateByCountry",
  async (data) => {
    try {
      const response = await DataService.post(
        API.Admin.GET_STATE_BY_COUNTRY,
        data
      );
      return response.data;
    } catch (error) {}
  }
);

export const getCityByState = createAsyncThunk(
  "admin/getCityByState",
  async (data) => {
    try {
      const response = await DataService.post(
        API.Admin.GET_CITY_BY_STATE,
        data
      );
      return response.data;
    } catch (error) {
      // toast.error(error.response.data.message);
    }
  }
);

export const updateWarehouseStatus = createAsyncThunk(
  "admin/updateWarehouseStatus",
  async (data) => {
    try {
      const response = await DataService.post(
        API.Admin.SET_ACTIVE_DEACTIVE_WAREHOUSE,
        data
      );
      return response.data;
    } catch (error) {
      toast.error(error?.response?.data?.message);
    }
  }
);

export const getStoreList = createAsyncThunk(
  "admin/getStoreList",
  async (data) => {
    try {
      const response = await DataService.post(API.Admin.GET_ALL_STORE, data);
      return response.data;
    } catch (error) {
      toast.error(error?.response?.data?.message);
    }
  }
);
export const getSingleStoreList = createAsyncThunk(
  "admin/getSingleStoreList",
  async (data) => {
    try {
      const response = await DataService.post(API.Admin.GET_SINGLE_STORE, data);
      return response?.data;
    } catch (error) {
      // toast.error(error.response.data.message);
    }
  }
);

export const addStoreData = createAsyncThunk(
  "admin/addStoreData",
  async (data) => {
    try {
      const response = await DataService.post(API.Admin.ADD_STORE, data);
      // toast.success(response?.data?.message);
      return response.data;
    } catch (error) {
      toast.error(error?.response?.data?.message);
    }
  }
);
export const deleteStoreData = createAsyncThunk(
  "admin/deleteStoreData",
  async (data) => {
    try {
      const response = await DataService.post(API.Admin.DELETE_STORE, data);
      toast.success(response?.data?.message);
      return response.data;
    } catch (error) {
      toast.error(error?.response?.data?.message);
    }
  }
);

export const editStoreList = createAsyncThunk(
  "admin/editStoreList",

  async (data) => {
    try {
      const response = await DataService.post(API.Admin.EDIT_STORE, data);
      return response.data;
    } catch (error) {
      toast.error(error?.response?.data?.message);
    }
  }
);
export const storeActivateDeactivate = createAsyncThunk(
  "admin/storeActivateDeactivate",
  async (data) => {
    try {
      const response = await DataService.post(
        API.Admin.STORE_ACTIVATE_DEACTIVATE,
        data
      );
      return response.data;
    } catch (error) {
      // toast.error(error.response.data.message);
    }
  }
);
export const getRoleList = createAsyncThunk(
  "admin/getRoleList",
  async (data) => {
    try {
      const response = await DataService.post(API.Admin.GET_ROLE_LIST, data);
      return response.data;
    } catch (error) {
      toast.error(error?.response?.data?.message);
    }
  }
);

export const getAllStoreList = createAsyncThunk(
  "admin/getAllStoreList",
  async (data) => {
    try {
      const response = await DataService.post(API.Admin.GET_STORE_LIST, data);
      return response.data;
    } catch (error) {
      toast.error(error?.response?.data?.message);
    }
  }
);

export const getAllEmployee = createAsyncThunk(
  "admin/getAllEmployee",
  async (data) => {
    try {
      const response = await DataService.get(API.Admin.GET_ALL_EMPLOYEE, data);
      return response.data;
    } catch (error) {
      toast.error(error?.response?.data?.message);
    }
  }
);
export const getNftStakeData = createAsyncThunk(
  "admin/getNftStakeData",
  async () => {
    try {
      const response = await DataService.get(API.Admin.GET_ALL_STAKE_LIST);
      return response.data;
    } catch (error) {
      toast.error(error?.response?.data?.message);
    }
  }
);

export const addEmployee = createAsyncThunk(
  "admin/addEmployee",
  async (data) => {
    try {
      const response = await DataService.post(API.Admin.ADD_EMPLOYEE, data);
      return response.data;
    } catch (error) {
      toast.error(error?.response?.data?.message);
    }
  }
);

export const editEmployee = createAsyncThunk(
  "admin/editEmployee",
  async (data) => {
    try {
      const response = await DataService.post(API.Admin.EDIT_EMPLOYEE, data);
      return response.data;
    } catch (error) {
      toast.error(error?.response?.data?.message);
    }
  }
);

export const getSingleEmployee = createAsyncThunk(
  "admin/getSingleEmployee",
  async (data) => {
    try {
      const response = await DataService.post(
        API.Admin.GET_SINGLE_EMPLOYEE,
        data
      );
      return response.data;
    } catch (error) {
      toast.error(error?.response?.data?.message);
    }
  }
);

export const setEmployeeActiveDeactive = createAsyncThunk(
  "admin/setEmployeeActiveDeactive",
  async (data) => {
    try {
      const response = await DataService.post(
        API.Admin.SET_ACTIVE_DEACTIVE_EMPLOYEE,
        data
      );
      return response.data;
    } catch (error) {
      toast.error(error?.response?.data?.message);
    }
  }
);

export const deleteEmployee = createAsyncThunk(
  "admin/deleteEmployee",
  async (data) => {
    try {
      const response = await DataService.post(API.Admin.DELETE_EMPLOYEE, data);
      return response.data;
    } catch (error) {
      toast.error(error?.response?.data?.message);
    }
  }
);

export const addRole = createAsyncThunk("admin/addRole", async (data) => {
  try {
    const response = await DataService.post(API.Admin.ADD_ROLE, data);
    return response.data;
  } catch (error) {
    toast.error(error?.response?.data?.message);
  }
});

export const editRole = createAsyncThunk("admin/editRole", async (data) => {
  try {
    const response = await DataService.post(API.Admin.EDIT_ROLE, data);
    return response.data;
  } catch (error) {
    toast.error(error?.response?.data?.message);
  }
});

export const getSingleRole = createAsyncThunk(
  "admin/getSingleRole",
  async (data) => {
    try {
      const response = await DataService.post(API.Admin.GET_SINGLE_ROLE, data);
      return response.data;
    } catch (error) {
      toast.error(error?.response?.data?.message);
    }
  }
);

export const setRoleActiveDeactive = createAsyncThunk(
  "admin/setRoleActiveDeactive",
  async (data) => {
    try {
      const response = await DataService.post(
        API.Admin.SET_ACTIVE_DEACTIVE_ROLE,
        data
      );
      return response.data;
    } catch (error) {
      toast.error(error?.response?.data?.message);
    }
  }
);

export const deleteRole = createAsyncThunk("admin/deleteRole", async (data) => {
  try {
    const response = await DataService.post(API.Admin.DELETE_ROLE, data);
    return response.data;
  } catch (error) {
    toast.error(error?.response?.data?.message);
  }
});

export const addCategory = createAsyncThunk(
  "admin/addCategory",
  async (data) => {
    try {
      const response = await DataService.post(API.Admin.ADD_CATEGORY, data);
      return response.data;
    } catch (error) {
      toast.error(error?.response?.data?.message);
    }
  }
);

export const getAllCategory = createAsyncThunk(
  "admin/getAllCategory",
  async (data) => {
    try {
      const response = await DataService.post(API.Admin.GET_ALL_CATEGORY, data);
      return response.data;
    } catch (error) {
      toast.error(error?.response?.data?.message);
    }
  }
);

export const getSingleCategory = createAsyncThunk(
  "admin/getSingleCategory",
  async (data) => {
    try {
      const response = await DataService.post(
        API.Admin.GET_SINGLE_CATEGORY,
        data
      );
      return response.data;
    } catch (error) {
      toast.error(error?.response?.data?.message);
    }
  }
);

export const editCategory = createAsyncThunk(
  "admin/editCategory",
  async (data) => {
    try {
      const response = await DataService.post(API.Admin.EDIT_CATEGORY, data);
      return response.data;
    } catch (error) {
      toast.error(error?.response?.data?.message);
    }
  }
);

export const deleteCategory = createAsyncThunk(
  "admin/deleteCategory",
  async (data) => {
    try {
      const response = await DataService.post(API.Admin.DELETE_CATEGORY, data);
      return response.data;
    } catch (error) {
      toast.error(error?.response?.data?.message);
    }
  }
);
export const deleteStake = createAsyncThunk(
  "admin/deleteStake",
  async (data) => {
    try {
      const response = await DataService.post(API.Admin.DELETE_STAKE, data);
      return response.data;
    } catch (error) {
      toast.error(error?.response?.data?.message);
    }
  }
);

export const setCategoryActiveDeactive = createAsyncThunk(
  "admin/setCategoryActiveDeactive",
  async (data) => {
    try {
      const response = await DataService.post(
        API.Admin.SET_ACTIVE_DEACTIVE_CATEGORY,
        data
      );
      return response.data;
    } catch (error) {
      toast.error(error?.response?.data?.message);
    }
  }
);

export const getAllSubCategory = createAsyncThunk(
  "admin/getAllSubCategory",
  async (data) => {
    try {
      const response = await DataService.post(
        API.Admin.GET_ALL_SUBCATEGORY,
        data
      );
      return response?.data;
    } catch (error) {
      toast.error(error?.response?.data?.message);
    }
  }
);
export const addSubCategory = createAsyncThunk(
  "admin/addSubCategory",
  async (data) => {
    try {
      const response = await DataService.post(API.Admin.ADD_SUB_CATEGORY, data);
      return response.data;
    } catch (error) {
      toast.error(error.response.data.message);
    }
  }
);
export const getSingleSubCategory = createAsyncThunk(
  "admin/getSingleSubCategory",
  async (data) => {
    try {
      const response = await DataService.post(
        API.Admin.GET_SINGLE_SUB_CATEGORY,
        data
      );
      return response.data;
    } catch (error) {
      toast.error(error.response.data.message);
    }
  }
);
export const deleteSubCategory = createAsyncThunk(
  "admin/deleteSubCategory",
  async (data) => {
    try {
      const response = await DataService.post(
        API.Admin.DELETE_SUB_CATEGORY,
        data
      );
      return response.data;
    } catch (error) {
      toast.error(error.response.data.message);
    }
  }
);
export const ediSubCategory = createAsyncThunk(
  "admin/ediSubCategory",
  async (data) => {
    try {
      const response = await DataService.post(
        API.Admin.EDIT_SUB_CATEGORY,
        data
      );
      return response.data;
    } catch (error) {
      toast.error(error.response.data.message);
    }
  }
);

export const addBrand = createAsyncThunk("admin/addBrand", async (data) => {
  try {
    const response = await DataService.post(API.Admin.ADD_BRAND, data);
    return response.data;
  } catch (error) {
    toast.error(error.response.data.message);
  }
});

export const getAllBrand = createAsyncThunk(
  "admin/getAllBrand",
  async (data) => {
    try {
      const response = await DataService.post(API.Admin.GET_ALL_BRAND, data);
      return response.data;
    } catch (error) {
      toast.error(error.response.data.message);
    }
  }
);

export const getSingleBrand = createAsyncThunk(
  "admin/getSingleBrand",
  async (data) => {
    try {
      const response = await DataService.post(API.Admin.GET_SINGLE_BRAND, data);
      return response.data;
    } catch (error) {
      toast.error(error.response.data.message);
    }
  }
);

export const editBrand = createAsyncThunk("admin/editBrand", async (data) => {
  try {
    const response = await DataService.post(API.Admin.EDIT_BRAND, data);
    return response.data;
  } catch (error) {
    toast.error(error.response.data.message);
  }
});

export const deleteBrand = createAsyncThunk(
  "admin/deleteBrand",
  async (data) => {
    try {
      const response = await DataService.post(API.Admin.DELETE_BRAND, data);
      return response.data;
    } catch (error) {
      toast.error(error.response.data.message);
    }
  }
);

export const setBrandActiveDeactive = createAsyncThunk(
  "admin/setBrandActiveDeactive",
  async (data) => {
    try {
      const response = await DataService.post(
        API.Admin.SET_ACTIVE_DEACTIVE_BRAND,
        data
      );
      return response.data;
    } catch (error) {
      toast.error(error.response.data.message);
    }
  }
);

export const updateSubCategoryStatus = createAsyncThunk(
  "admin/updateSubCategoryStatus",
  async (data) => {
    try {
      const response = await DataService.post(
        API.Admin.ACTIVE_DEACTIVE_SUB_CATEGORY,
        data
      );
      return response.data;
    } catch (error) {
      toast.error(error.response.data.message);
    }
  }
);

export const addProduct = createAsyncThunk("admin/addProduct", async (data) => {
  try {
    const response = await DataService.post(API.Admin.ADD_PRODUCT, data);
    return response.data;
  } catch (error) {
    toast.error(error.response.data.message);
  }
});

export const getAllProduct = createAsyncThunk(
  "admin/getAllProduct",
  async (data) => {
    try {
      const response = await DataService.get(
        `${API.Admin.GET_ALL_PRODUCTS}?page=${data.currentPage}&limit=${data.limit}`
      );
      return response.data;
    } catch (error) {
      toast.error(error.response.data.message);
    }
  }
);
export const updateProductStatus = createAsyncThunk(
  "admin/updateProductStatus",
  async (data) => {
    try {
      const response = await DataService.post(
        API.Admin.ACTIVE_DEACTIVE_PRODUCT,
        data
      );
      return response.data;
    } catch (error) {
      toast.error(error.response.data.message);
    }
  }
);

export const updateWarehouseProductStock = createAsyncThunk(
  "admin/updateWarehouseProductStock",
  async (data) => {
    try {
      const response = await DataService.post(
        API.Admin.UPDATE_WAREHOUSE_PRODUCTS_STOCK,
        data
      );
      return response.data;
    } catch (error) {
      toast.error(error.response.data.message);
    }
  }
);

export const getWarehouseProductStock = createAsyncThunk(
  "admin/getWarehouseProductStock",
  async (data) => {
    try {
      const response = await DataService.post(
        API.Admin.GET_WAREHOUSE_PRODUCTS_STOCK,
        data
      );
      return response.data;
    } catch (error) {
      toast.error(error.response.data.message);
    }
  }
);
export const getWarehouseProductStockHistory = createAsyncThunk(
  "admin/getWarehouseProductStockHistory",
  async (data) => {
    try {
      const response = await DataService.post(
        API.Admin.GET_WAREHOUSE_PRODUCTS_STOCK_HISTORY,
        data
      );
      return response.data;
    } catch (error) {
      toast.error(error.response.data.message);
    }
  }
);

export const deleteProductData = createAsyncThunk(
  "admin/deleteProductData",
  async (data) => {
    try {
      const response = await DataService.post(API.Admin.DELETE_PRODUCT, data);
      toast.success(response?.data?.message);
      return response.data;
    } catch (error) {
      toast.error(error?.response?.data?.message);
    }
  }
);
export const getSingleProduct = createAsyncThunk(
  "admin/getSingleProduct",
  async (data) => {
    try {
      const response = await DataService.post(
        API.Admin.GET_SINGLE_PRODUCT,
        data
      );
      return response?.data;
    } catch (error) {
      // toast.error(error.response.data.message);
    }
  }
);

export const editProduct = createAsyncThunk(
  "admin/editProduct",

  async (data) => {
    try {
      const response = await DataService.post(API.Admin.EDIT_PRODUCT, data);
      return response.data;
    } catch (error) {
      toast.error(error?.response?.data?.message);
    }
  }
);

export const getAllSupplier = createAsyncThunk(
  "admin/getAllSupplier",
  async (data) => {
    try {
      const response = await DataService.get(API.Admin.GET_ALL_SUPPLIER, data);
      return response.data;
    } catch (error) {
      toast.error(error.response.data.message);
    }
  }
);

export const addSupplier = createAsyncThunk(
  "admin/addSupplier",
  async (data) => {
    try {
      const response = await DataService.post(API.Admin.ADD_SUPPLIER, data);
      return response.data;
    } catch (error) {
      toast.error(error.response.data.message);
    }
  }
);

export const editSupplier = createAsyncThunk(
  "admin/editSupplier",
  async (data) => {
    try {
      const response = await DataService.post(API.Admin.EDIT_SUPPLIER, data);
      return response.data;
    } catch (error) {
      toast.error(error.response.data.message);
    }
  }
);

export const getSingleSupplier = createAsyncThunk(
  "admin/getSingleSupplier",
  async (data) => {
    try {
      const response = await DataService.post(
        API.Admin.GET_SINGLE_SUPPLIER,
        data
      );
      return response.data;
    } catch (error) {
      toast.error(error.response.data.message);
    }
  }
);

export const setSupplierActiveDeactive = createAsyncThunk(
  "admin/setSupplierActiveDeactive",
  async (data) => {
    try {
      const response = await DataService.post(
        API.Admin.SET_ACTIVE_DEACTIVE_SUPPLIER,
        data
      );
      return response.data;
    } catch (error) {
      toast.error(error.response.data.message);
    }
  }
);

export const deleteSupplier = createAsyncThunk(
  "admin/deleteSupplier",
  async (data) => {
    try {
      const response = await DataService.post(API.Admin.DELETE_SUPPLIER, data);
      return response.data;
    } catch (error) {
      toast.error(error.response.data.message);
    }
  }
);

export const getAllCMS = createAsyncThunk("admin/getAllCMS", async () => {
  try {
    const response = await DataService.get(API.Admin.GET_ALL_CMS);
    return response.data;
  } catch (error) {
    toast.error(error.response.data.message);
  }
});

export const updateAboutUs = createAsyncThunk(
  "admin/updateAboutUs",
  async (data) => {
    try {
      const response = await DataService.post(API.Admin.UPDATE_ABOUT_US, data);
      return response.data;
    } catch (error) {
      toast.error(error.response.data.message);
    }
  }
);

export const updateTermCondition = createAsyncThunk(
  "admin/updateTermCondition",
  async (data) => {
    try {
      const response = await DataService.post(
        API.Admin.UPDATE_TERM_CONDITION,
        data
      );
      return response.data;
    } catch (error) {
      toast.error(error.response.data.message);
    }
  }
);

export const updatePrivacyPolicy = createAsyncThunk(
  "admin/updatePrivacyPolicy",
  async (data) => {
    try {
      const response = await DataService.post(
        API.Admin.UPDATE_PRIVACY_POLICY,
        data
      );
      return response.data;
    } catch (error) {
      toast.error(error.response.data.message);
    }
  }
);
export const getWarehouseList = createAsyncThunk(
  "admin/getWarehouseList",
  async (data) => {
    try {
      const res = await DataService.post(API.Admin.GET_ALL_WAREHOUSE, data);
      return res.data;
    } catch (error) {
      toast.error(error.response.data.message);
    }
  }
);

export const getSingleUserDetails = createAsyncThunk(
  "admin/getSingleUserDetails",
  async (userId) => {
    try {
      const response = await DataService.get(
        `${API.Admin.GET_SINGLE_USER_DETAILS}?id=${userId}`
      );
      console.log(response, "response");
      return response.data;
    } catch (error) {
      toast.error(error.response.data.message);
    }
  }
);

export const addChallenge = createAsyncThunk(
  "admin/addChallenge",
  async (data) => {
    try {
      const response = await DataService.post(
        API.Admin.ADD_ADMIN_CHALLENGE,
        data
      );
      return response.data;
    } catch (error) {
      toast.error(error.response.data.message);
    }
  }
);
export const getOrderList = createAsyncThunk("admin/getOrderList", async () => {
  try {
    const response = await DataService.get(API.Admin.GET_ORDER_LIST);
    return response.data;
  } catch (error) {
    // toast.error(error.response.data.message);
  }
});
export const getSingleOrderDetails = createAsyncThunk(
  "admin/getSingleOrderDetails",
  async (data) => {
    try {
      const response = await DataService.post(
        API.Admin.GET_SINGLE_ORDER_DETAILS,
        data
      );
      return response.data;
    } catch (error) {
      toast.error(error.response.data.message);
    }
  }
);
export const getAllTicketList = createAsyncThunk(
  "admin/getAllTicketList",
  async (data) => {
    try {
      let url = data ? `${API.Admin.GET_TICKET_LIST}?subAdminId=${data}` : API.Admin.GET_TICKET_LIST 
      const response = await DataService.get(url);
      return response.data;
    } catch (error) {
      toast.error(error.response.data.message);
    }
  }
);
export const updateTicketStatus = createAsyncThunk(
  "admin/updateTicketStatus",
  async (data) => {
    try {
      const response = await DataService.post(
        API.Admin.UPDATE_TICKET_STATUS,
        data
      );
      return response;
    } catch (error) {
      toast.error(error.response.data.message);
    }
  }
);

// get all coupons
export const getAllCoupons = createAsyncThunk(
  "admin/getAllCoupons",
  async () => {
    try {
      const response = await DataService.get(
        `${API.Admin.GET_ALL_COUPONS}?page=1`
      );
      return response.data;
    } catch (error) {
      // toast.error(error?.response?.data?.message);
    }
  }
);

// Add Coupon
export const addCouponData = createAsyncThunk(
  "admin/addCouponData",
  async (data) => {
    try {
      const response = await DataService.post(API.Admin.ADD_COUPON, data);
      toast.success(response?.data?.message);
      return response.data;
    } catch (error) {
      toast.error(error?.response?.data?.message);
    }
  }
);
// Delete Coupon
export const deleteCouponData = createAsyncThunk(
  "admin/deleteCouponData",
  async (data) => {
    try {
      const response = await DataService.post(API.Admin.DELETE_COUPON, data);
      return response.data;
    } catch (error) {
      toast.error(error?.response?.data?.message);
    }
  }
);

// Active deactive coupon status
export const couponActivateDeactivate = createAsyncThunk(
  "admin/couponActivateDeactivate",
  async (data) => {
    try {
      const response = await DataService.post(
        API.Admin.ACTIVATE_DEACTIVATE_COUPON,
        data
      );
      return response.data;
    } catch (error) {
      toast.error(error.response.data.message);
    }
  }
);

export const getProductListForComboOffer = createAsyncThunk(
  "admin/getProductList",
  async () => {
    try {
      const response = await DataService.get(
        API.Admin.GET_PRODUCTS_FOR_COMBO_OFFER
      );
      return response.data;
    } catch (error) {
      toast.error(error?.response?.data?.message);
    }
  }
);

export const updateChallenge = createAsyncThunk(
  "admin/updateChallenge",
  async (data) => {
    try {
      const response = await DataService.post(
        API.Admin.UPDATE_ADMIN_CHALLANGE,
        data
      );
      return response.data;
    } catch (error) {
      toast.error(error.response.data.message);
    }
  }
);

export const deleteChallenge = createAsyncThunk(
  "admin/deleteChallenge",
  async (data) => {
    try {
      const response = await DataService.post(API.Admin.DELETE_CHALLANGE, data);
      return response.data;
    } catch (error) {
      toast.error(error.response.data.message);
    }
  }
);

export const getAllNftTransactionList = createAsyncThunk(
  "admin/getAllNftTransactionList",
  async () => {
    try {
      const response = await DataService.get(
        API.Admin.GET_NFT_TRANSACTION_LIST
      );
      return response.data;
    } catch (error) {
      toast.error(error.response.data.message);
    }
  }
);

export const getSingleChallengeDetails = createAsyncThunk(
  "admin/getSingleChallengeDetails",
  async (data) => {
    try {
      const response = await DataService.post(
        API.Admin.GET_SINGLE_CHALLENGE_DETAILS,
        data
      );
      return response.data;
    } catch (error) {
      toast.error(error.response.data.message);
    }
  }
);

export const getOrdersRatingList = createAsyncThunk(
  "admin/getOrdersRatingList",
  async () => {
    try {
      const response = await DataService.get(API.Admin.GET_ORDER_RATING_LIST);
      return response.data;
    } catch (error) {}
  }
);

export const getProductRatingList = createAsyncThunk(
  "admin/getProductRatingList",
  async (data) => {
    try {
      const response = await DataService.post(
        API.Admin.GET_PRODUCT_RATING_LIST,
        data
      );
      return response.data;
    } catch (error) {}
  }
);

export const deleteOrderRating = createAsyncThunk(
  "admin/deleteOrderRating",
  async (data) => {
    try {
      const response = await DataService.post(
        API.Admin.DELETE_ORDER_RATING,
        data
      );
      return response.data;
    } catch (error) {
      toast.error(error.response.data.message);
    }
  }
);
export const getAllAppointmentList = createAsyncThunk(
  "admin/getAllAppointmentList",
  async () => {
    try {
      const response = await DataService.get(API.Admin.GET_APPOINTMENT_LIST);
      return response.data;
    } catch (error) {
      // toast.error(error.response.data.message);
    }
  }
);
export const getOrderListByCouponId = createAsyncThunk(
  "admin/getOrderListByCouponId",
  async (couponId) => {
    try {
      const response = await DataService.get(
        API.Admin.COUPON_ORDER_LIST + "/" + couponId
      );
      return response.data;
    } catch (error) {}
  }
);
export const getAllSessionList = createAsyncThunk(
  "admin/getSessionList",
  async (data) => {
    try {
      const response = await DataService.post(API.Admin.SESSION_LIST, data);
      return response.data;
    } catch (error) {}
  }
);
export const approveRejectSession = createAsyncThunk(
  "admin/approveRejectSession",
  async (data) => {
    try {
      const response = await DataService.post(
        API.Admin.APPROVE_REJECT_SESSION,
        data
      );
      toast.success(response?.data?.message);
      return response.data;
    } catch (error) {
      toast.error(error.response.data.message);
    }
  }
);
export const getAllSubSessionList = createAsyncThunk(
  "admin/getAllSubSessionList",
  async (data) => {
    try {
      const response = await DataService.post(
        API.Admin.GET_SUB_SESSION_LIST,
        data
      );
      return response.data;
    } catch (error) {}
  }
);
export const approveRejectSubSession = createAsyncThunk(
  "admin/approveRejectSession",
  async (data) => {
    try {
      const response = await DataService.post(
        API.Admin.APPROVE_REJECT_SUB_SESSION,
        data
      );
      toast.success(response?.data?.message);
      return response.data;
    } catch (error) {
      toast.error(error.response.data.message);
    }
  }
);
export const getSingleSession = createAsyncThunk(
  "admin/getSingleSession",
  async (sessionId) => {
    try {
      const response = await DataService.post(
        API.Admin.GET_SINGLE_SESSION_DETAILS,
        { sessionId }
      );
      return response.data;
    } catch (error) {
      toast.error(error.response.data.message);
    }
  }
);

export const getTrainerTimingDetails = createAsyncThunk(
  "admin/getTrainerTimingDetails",
  async (trainerId) => {
    try {
      const response = await DataService.post(
        API.Admin.GET_TRAINER_TIMING_DETAILS,
        { trainerId }
      );
      return response.data;
    } catch (error) {}
  }
);

export const getAllSubscribedSessionList = createAsyncThunk(
  "admin/getAllSubscribedSessionList",
  async () => {
    try {
      const response = await DataService.get(
        API.Admin.GET_SUBSCRIBED_SESSION_LIST
      );
      return response.data;
    } catch (error) {}
  }
);
export const addEditNutrition = createAsyncThunk(
  "admin/addEditNutrition",
  async (data) => {
    try {
      const response = await DataService.post(
        API.Admin.ADD_EDIT_NUTRITION,
        data
      );
      toast.success(response?.data?.message);
      return response.data;
    } catch (error) {
      toast.error(error?.response?.data?.message);
    }
  }
);
export const getAllNutritionPlans = createAsyncThunk(
  "admin/getAllNutritionPlans",
  async () => {
    try {
      const response = await DataService.get(API.Admin.GET_ALL_NUTRITION_PLAN);
      return response.data;
    } catch (error) {}
  }
);
export const deleteNutrationPlan = createAsyncThunk(
  "admin/deleteNutrationPlan",
  async (planId) => {
    try {
      const response = await DataService.post(API.Admin.DELETE_NUTRATION_PLAN, {
        planId,
      });
      toast.success(response?.data?.message);
      return response.data;
    } catch (error) {
      toast.error(error?.response?.data?.message);
    }
  }
);
export const getPostActiveDeactive = createAsyncThunk(
  "admin/adminPostActiveDeactive",
  async (data) => {
    try {
      const response = await DataService.post(
        API.Admin.GET_POST_ACTIVE_DEACTIVE,
        data
      );
      toast.success(response?.data?.message);
      return response.data;
    } catch (error) {
      toast.error(error?.response?.data?.message);
    }
  }
);
export const getChallengeStepsDetails = createAsyncThunk(
  "admin/getChallengeStepsDetails",
  async (data) => {
    try {
      const response = await DataService.post(
        API.Admin.CHALLENGE_STEPS_DETAILS,
        data
      );
      return response.data;
    } catch (error) {}
  }
);

export const addSetFaqList = createAsyncThunk("admin/addFaq", async (data) => {
  try {
    const response = await DataService.post(API.Admin.ADD_SET_FAQ, data);
    toast.success(response?.data?.message);
    return response;
  } catch (error) {
    toast.error(error?.response?.data?.message);
  }
});

export const getFaqList = createAsyncThunk("admin/getFaqList", async () => {
  try {
    const response = await DataService.get(`${API.Admin.GET_FAQ}`);
    return response.data;
  } catch {}
});

export const deleteFaqList = createAsyncThunk(
  "admin/deleteFaqList",
  async (data) => {
    try {
      const response = await DataService.post(API.Admin.DELETE_FAQ, data);
      return response.data;
    } catch (error) {
      toast.error(error?.response?.data?.message);
    }
  }
);

export const getAllTrainerSessionList = createAsyncThunk(
  "admin/get-trainer-session-list",
  async (data) => {
    try {
      const response = await DataService.post(API.Admin.SESSION_LIST, data);
      return response.data;
    } catch (error) {}
  }
);

export const getAllAppointment = createAsyncThunk(
  "admin/get-all-appointments-of-single-user",
  async (data) => {
    try {
      const response = await DataService.post(API.Admin.GET_APPOINTMENT, data);
      return response.data;
    } catch (error) {}
  }
);

export const getNutritionTabPlans = createAsyncThunk(
  "admin/get-all-nutrition-plan-of-single-user",
  async (data) => {
    try {
      const response = await DataService.post(
        API.Admin.GET_NUTRITION_LIST_PLAN,
        data
      );
      return response.data;
    } catch (error) {}
  }
);

export const getUserActiveDeactive = createAsyncThunk(
  "admin/adminUserActive",
  async (data) => {
    try {
      const response = await DataService.post(
        API.Admin.GET_USER_ACTIVE_DEACTIVE,
        data
      );
      toast.success(response?.data?.message);
      return response.data;
    } catch (error) {
      toast.error(error?.response?.data?.message);
    }
  }
);

export const getWorkoutPlan = createAsyncThunk(
  "admin/get-all-workout-plan-of-single-user",
  async (data) => {
    try {
      const response = await DataService.post(API.Admin.GET_WORKOUT_PLAN, data);
      return response.data;
    } catch (error) {}
  }
);

export const getNutritionList = createAsyncThunk(
  "admin/get-nutrition-plan-list",
  async () => {
    try {
      const response = await DataService.get(API.Admin.GET_NUTRITION_LIST);
      return response.data;
    } catch (error) {}
  }
);

export const getWorkoutList = createAsyncThunk(
  "admin/get-workout-plan-list",
  async () => {
    try {
      const response = await DataService.get(API.Admin.GET_WORKOUT_LIST);
      return response.data;
    } catch (error) {}
  }
);

export const getAllOrderTicketList = createAsyncThunk(
  "admin/getAllOrderTicketList",
  async () => {
    try {
      const response = await DataService.get(
        API.Admin.GET_ALL_ORDER_TICKET_LIST
      );
      return response.data;
    } catch (error) {}
  }
);

export const sendTicketReply = createAsyncThunk(
  "admin/sendTicketReply",
  async (data) => {
    try {
      const response = await DataService.post(
        API.Admin.SEND_TICKET_REPLY,
        data
      );
      toast.success(response?.data?.message);
      return response.data;
    } catch (error) {
      toast.error(error?.response?.data?.message);
    }
  }
);

export const getAdminNotificationList = createAsyncThunk(
  "admin/getAdminNotificationList",
  async () => {
    try {
      const response = await DataService.get(API.Admin.GET_ADMIN_NOTIFICATIONS);
      return response.data;
    } catch (error) {
      // toast.error(error?.response?.data?.message);
    }
  }
);

export const getAllTrainerCreatedProduct = createAsyncThunk(
  "admin/getAllTrainerProduct",
  async (data) => {
    try {
      const response = await DataService.get(
        `${API.Admin.GET_TRAINER_CREATED_PRODUCT}?page=${data.currentPage}&limit=${data.limit}`
      );
      return response.data;
    } catch (error) {}
  }
);
export const approveRejectTrainerProduct = createAsyncThunk(
  "admin/approveRejectTrainerProduct",
  async (data) => {
    try {
      const response = await DataService.post(
        API.Admin.APPROVE_REJECT_TRAINER_PRODUCT,
        data
      );
      toast.success(response?.data?.message);
      return response.data;
    } catch (error) {
      toast.error(error?.response?.data?.message);
    }
  }
);
export const getProductListWithoutPagination = createAsyncThunk(
  "admin/getProductListWithoutPagination",
  async () => {
    try {
      const response = await DataService.get(
        `${API.Admin.GET_ALL_PRODUCTS}?type=withoutPagination`
      );
      return response.data;
    } catch (error) {}
  }
);
export const addWarehouseProductStock = createAsyncThunk(
  "admin/addWarehouseProductStock",
  async (data) => {
    try {
      const response = await DataService.post(
        API.Admin.ADD_WAREHOUSE_STOCK,
        data
      );
      toast.success(response?.data?.message);
      return response.data;
    } catch (error) {
      toast.error(error?.response?.data?.message);
    }
  }
);
export const transferProductStock = createAsyncThunk(
  "admin/transferProductStock",
  async (data) => {
    try {
      const response = await DataService.post(
        API.Admin.TRANSFER_WAREHOUSE_STOCK,
        data
      );
      toast.success(response?.data?.message);
      return response.data;
    } catch (error) {
      toast.error(error?.response?.data?.message);
    }
  }
);
export const addChallengeWinnerAndReward = createAsyncThunk(
  "admin/addChallengeWinnerAndeward",
  async (data) => {
    try {
      const response = await DataService.post(
        API.Admin.ADD_CHALLENGE_WINNER_REWARDS,
        data
      );
      return response.data;
    } catch (error) {
      toast.error(error?.response?.data?.message);
    }
  }
);

export const getSingleNFTTransactionList = createAsyncThunk(
  "admin/getSingleNftTransactions",
  async (nftIds) => {
    try {
      // const response = await DataService.get(`${API.Admin.GET_SINGLE_NFT_TRANSACTIONS}?nftId=${nftIds}`);
      const response = await DataService.get(
        API.Admin.GET_SINGLE_NFT_TRANSACTIONS + "/" + nftIds
      );
      return response.data;
    } catch (error) {}
  }
);

// Remove post comments
export const adminRemovePostParentComment = createAsyncThunk(
  "admin/removePostComment",
  async (data) => {
    try {
      // const response = await DataService.get(`${API.Admin.REMOVE_POST_PARENT_COMMENT}/${data}`);
      const response = await DataService.post(
        API.Admin.REMOVE_POST_PARENT_COMMENT,
        data
      );
      toast.success(response?.data?.message);
      return response.data;
    } catch (error) {
      toast.error(error?.response?.data?.message);
    }
  }
);

// Remove post child comments
export const adminRemovePostChildComment = createAsyncThunk(
  "admin/removePostChildComment",
  async (data) => {
    try {
      // const response = await DataService.get(`${API.Admin.REMOVE_POST_CHILD_COMMENT}/${data}`);
      const response = await DataService.post(
        API.Admin.REMOVE_POST_CHILD_COMMENT,
        data
      );
      toast.success(response?.data?.message);
      return response.data;
    } catch (error) {
      toast.error(error?.response?.data?.message);
    }
  }
);

// Get NFT Repair List
export const getNFTRepairList = createAsyncThunk("admin/getNFTRepairList", async (data) => {
    try {
      const response = await DataService.get(
        `${API.Admin.GET_NFT_REPAIR_LIST}${data}`
      );
      return response.data;
    } catch (error) {
      // toast.error(error.response.data.message);
    }
  }
);

// Add Edit NFT Repair api
export const addEditNftRepairData = createAsyncThunk("admin/addEditNftRepairData", async (data) => {
    try {
      const response = await DataService.post(API.Admin.ADD_NFT_REPAIR_DATA, data);
      toast.success(response?.data?.message);
      return response;
    } catch (error) {
      toast.error(error?.response?.data?.message);
    }
  }
);

// Delete NFT Repair api
export const deleteNFTRepair = createAsyncThunk("admin/deleteNftRepair",
  async (data) => {
    try {
      const response = await DataService.post(API.Admin.DELETE_NFT_REPAIR_DATA, data);
      return response.data;
    } catch (error) {
      toast.error(error?.response?.data?.message);
    }
  }
);

export const buyPresaleToken = createAsyncThunk("admin/buyPresaleToken", async (data) => {
  try {
    const response = await DataService.post(API.Admin.BUY_PRESALE_TOKEN, data);
    toast.success(response?.data?.message);
    return response.data;
  } catch (error) {
    toast.error(error?.response?.data?.message);
  }
}
);

export const getAllPresaleTokenList = createAsyncThunk("admin/getAllPresaleTokenList", async (userId) => {
  try {
    const response = await DataService.get(`${API.Admin.GET_ALL_PRESALE_TOKEN_LIST}/${userId}`);
    return response.data;
  } catch (error) {
  }
}
);

export const stakePresaleToken = createAsyncThunk("admin/stakePresaleToken", async (data) => {
  try {
    const response = await DataService.post(API.Admin.STAKE_PRESALE_TOKEN, data);
    toast.success(response?.data?.message);
    return response.data;
  } catch (error) {
    toast.error(error?.response?.data?.message);
  }
}
);

export const getStakePresaleTokenList = createAsyncThunk("admin/getStakePresaleTokenList", async (userId) => {
  try {
    const response = await DataService.get(`${API.Admin.GET_STAKE_PRESALE_TOKEN}/${userId}`);
    return response.data;
  } catch (error) {
  }
}
);
export const getLeaseNftList = createAsyncThunk("admin/getLeaseNftList", async () => {
  try {
    const response = await DataService.get(API.Admin.GET_LEASE_NFT_LIST);
    return response.data;
  } catch (error) {
  }
}
);
export const getSingleLeaseNftHistory = createAsyncThunk("admin/getSingleLeaseNftHistory", async (nftid) => {
  try {
    const response = await DataService.get(`${API.Admin.GET_SINGLE_LEASE_NFT_HISTORY}/${nftid}`);
    return response.data;
  } catch (error) {
  }
}
);
export const readUnreadNotification = createAsyncThunk("admin/readUnreadNotification", async (data) => {
    try {
      const response = await DataService.post(API.Admin.POST_READ_UNREAD_NOTIFICATION, data);
      // toast.success(response?.data?.message);
      return response.data;
    } catch (error) {
      toast.error(error?.response?.data?.message);
    }
  }
);

// Get Repair NFT Request List
export const getRepairNFTRequestList = createAsyncThunk("admin/getRepairNFTRequestList", async () => {
  try {
    const response = await DataService.get(API.Admin.GET_REPAIR_NFT_REQUEST_LIST);
    return response.data;
  } catch (error) {
    // toast.error(error.response.data.message);
  }
}
);

// Winner Declaration Status
export const winnerDeclarationStatus = createAsyncThunk(
  "admin/winnerDeclarationStatus",
  async (data) => {
    try {
      const response = await DataService.post(API.Admin.GET_UPDATE_WINNER_DECLARATION_REQUEST, data);
      toast.success(response?.data?.message);
      return response.data;
    } catch (error) {}
  }
);

export const getWarehouseStockReport = createAsyncThunk(
  "admin/getWarehouseStockReport",
  async (data) => {
    try {
      const response = await DataService.post(API.Admin.GET_WAREHOUSE_STOCK_REPORT, data);
      return response.data;
    } catch (error) {}
  }
);

export const getOrderSalesReport = createAsyncThunk(
  "admin/getOrderSalesReport",
  async (data) => {
    try {
      const response = await DataService.post(API.Admin.GET_ORDER_SALES_REPORT, data);
      return response.data;
    } catch (error) {}
  }
);

export const getSubEmployeeList = createAsyncThunk(
  "admin/getSubEmployeeList",
  async (data) => {
    try {
      const response = await DataService.get(API.Admin.GET_SUB_ADMINS);
      return response.data;
    } catch (error) {}
  }
);

// Add edit sub admins
export const addEditSubAdmin = createAsyncThunk("admin/addEditSubAdmin", async (data) => {
  try {
    const response = await DataService.post(API.Admin.ADD_SUB_ADMIN, data);
    toast.success(response?.data?.message);
    return response;
  } catch (error) {
    toast.error(error?.response?.data?.message);
  }
}
);

// Delete sub admin
export const deleteSubAdmin = createAsyncThunk("admin/deleteSubAdmin", async (data) => {
  try {
    const response = await DataService.post(API.Admin.DELETE_SUB_ADMIN, data);
    toast.success(response?.data?.message);
    return response;
  } catch (error) {
    toast.error(error?.response?.data?.message);
  }
});

export const assignSubAdmin = createAsyncThunk("admin/assignSubAdmin", async (data) => {
  try {
    const response = await DataService.post(API.Admin.ASSIGN_SUB_ADMIN, data);
    toast.success(response?.data?.message);
    return response;
  } catch (error) {
    toast.error(error?.response?.data?.message);
  }
});
export const getDailyActiveUsers = createAsyncThunk("admin/get-daily-active-users-list", async (date) => {
    try {
      const response = await DataService.get(
        `${API.Admin.GET_ALL_DAILY_ACTIVE_USERS}?date=${date}`
      );
      return response.data;
    } catch (error) {}
  }
);

export const getMonthYearActiveUsers = createAsyncThunk("admin/get-monthly-active-users-list", async (data) => {
  try {
    const response = await DataService.get(
      `${API.Admin.GET_ALL_MONTHLY_ACTIVE_USERS}?month=${data.month}&year=${data.year}`
    );
    return response.data;
  } catch (error) {}
}
);

export const postTrainerReview_List = createAsyncThunk("admin/trainer-reviews",
  async (data) => {
    try {
      const res = await DataService.post(API.Admin.POST_TRAINER_REVIEW_DATA, data);
      return res.data;
    } catch {}
  }
);

//  Center type functions 
export const getCenterTypeList = createAsyncThunk("admin/get-center-type-list",
  async () => {
    try {
      const response = await DataService.get(API.Admin.GET_CENTER_TYPES);
      return response.data;
    } catch (error) {
      toast.error(error.response.data.message);
    }
  }
);

export const addCenterTypePostData = createAsyncThunk("admin/addCenterTypePostData",
  async (data) => {
    try {
      const response = await DataService.post(API.Admin.ADD_CENTER_TYPE_DATA, data);
      toast.success(response?.data?.message);
      return response;
    } catch (error) {
      toast.error(error?.response?.data?.message);
    }
  }
);

export const deleteCenterType = createAsyncThunk("admin/deleteCenterType",
  async (data) => {
    try {
      const response = await DataService.post(API.Admin.DELETE_CENTER_TYPE_DATA, data);
      return response.data;
    } catch (error) {
      toast.error(error?.response?.data?.message);
    }
  }
);

// Amenities functions
export const getAmenitiesList = createAsyncThunk("admin/get-amenities-list",
  async () => {
    try {
      const response = await DataService.get(API.Admin.GET_AMENITIES_LIST);
      return response.data;
    } catch (error) {
      toast.error(error.response.data.message);
    }
  }
);

export const addEditAmenities = createAsyncThunk("admin/addEditAmenities",
  async (data) => {
    try {
      const response = await DataService.post(API.Admin.ADD_EDIT_AMENITIES, data);
      toast.success(response?.data?.message);
      return response.data;
    } catch (error) {
      toast.error(error?.response?.data?.message);
    }
  }
);

export const deleteAmenitiesData = createAsyncThunk("admin/deleteAmenitiesData",
  async (data) => {
    try {
      const response = await DataService.post(API.Admin.DELETE_AMENITIES, data);
      toast.success(response?.data?.message);
      return response.data;
    } catch (error) {
      toast.error(error?.response?.data?.message);
    }
  }
);

// Equipment functions
export const getEquipmentList = createAsyncThunk("admin/getEquipmentList",
  async () => {
    try {
      const response = await DataService.get(API.Admin.GET_EQUIPMENT_LIST);
      return response.data;
    } catch (error) {
      toast.error(error.response.data.message);
    }
  }
);

export const addEditEquipment = createAsyncThunk("admin/addEditEquipment",
  async (data) => {
    try {
      const response = await DataService.post(API.Admin.ADD_EDIT_EQUIPMENT, data);
      toast.success(response?.data?.message);
      return response.data;
    } catch (error) {
      toast.error(error?.response?.data?.message);
    }
  }
);

export const deleteEquipmentData = createAsyncThunk("admin/deleteEquipmentData",
  async (data) => {
    try {
      const response = await DataService.post(API.Admin.DELETE_EQUIPMENT, data);
      toast.success(response?.data?.message);
      return response.data;
    } catch (error) {
      toast.error(error?.response?.data?.message);
    }
  }
);

// Services functions
export const getServicesList = createAsyncThunk("admin/getServicesList",
  async () => {
    try {
      const response = await DataService.get(API.Admin.GET_SERVICE_LIST);
      return response.data;
    } catch (error) {
      toast.error(error.response.data.message);
    }
  }
);

export const addEditServices = createAsyncThunk("admin/addEditServices",
  async (data) => {
    try {
      const response = await DataService.post(API.Admin.ADD_EDIT_SERVICE, data);
      toast.success(response?.data?.message);
      return response.data;
    } catch (error) {
      toast.error(error?.response?.data?.message);
    }
  }
);

export const deleteServiceData = createAsyncThunk("admin/deleteServiceData",
  async (data) => {
    try {
      const response = await DataService.post(API.Admin.DELETE_SERVICE, data);
      toast.success(response?.data?.message);
      return response.data;
    } catch (error) {
      toast.error(error?.response?.data?.message);
    }
  }
);

export const getSponsoredPost = createAsyncThunk("admin/getSponsoredPost",
  async () => {
    try {
      const response = await DataService.get(API.Admin.GET_ALL_SPONSORED_POST);
      return response.data;
    } catch (error) {}
  }
);

// Mark As Sponsored 
export const addAsSponsoredData = createAsyncThunk("admin/addAsSponsoredData",
  async (data) => {
    try {
      const response = await DataService.post(API.Admin.ADD_SPONSORED_POST, data);
      toast.success(response?.data?.message);
      return response.data;
    } catch (error) {
      toast.error(error?.response?.data?.message);
    }
  }
);

export const getCenterReviewList = createAsyncThunk("admin/getCenterReviewList", async (ids) => {
  try {
    const res = await DataService.get(`${API.Admin.GET_CENTER_REVIEWS_LIST}?healthCenterId=${ids}`);
    return res.data;
  } catch {}
}
);

export const centerCommentRemoved = createAsyncThunk("admin/centerCommentRemoved", async (data) => {
    try {
      const response = await DataService.post(API.Admin.DELETE_CENTER_COMMENT, data);
      toast.success(response?.data?.message);
      return response.data;
    } catch (error) {
      toast.error(error?.response?.data?.message);
    }
  }
);

export const getCenterRatingList = createAsyncThunk(
  "admin/getCenterRatingList",
  async () => {
    try {
      const response = await DataService.get(API.Admin.GET_CENTER_RATING_LIST);
      return response.data;
    } catch (error) {}
  }
);

export const deleteCenterRating = createAsyncThunk("admin/deleteCenterRating", async (data) => {
  try {
    const response = await DataService.post(API.Admin.DELETE_CENTER_COMMENT, data);
    toast.success(response?.data?.message);
    return response.data;
  } catch (error) {
    toast.error(error?.response?.data?.message);
  }
}
);

export const getTrainerRatingList = createAsyncThunk("admin/getTrainerRatingList",
  async () => {
    try {
      const response = await DataService.get(API.Admin.GET_TRAINER_RATING_LIST);
      return response.data;
    } catch (error) {}
  }
);

export const deleteTrainerRating = createAsyncThunk("admin/deleteTrainerRating", async (data) => {
  try {
    const response = await DataService.post(API.Admin.DELETE_TRAINER_COMMENT, data);
    toast.success(response?.data?.message);
    return response.data;
  } catch (error) {
    toast.error(error?.response?.data?.message);
  }
}
);