import * as Yup from "yup";
import { parsePhoneNumberFromString as parsePhoneNumber } from 'libphonenumber-js';

const textAndSpaceOnly = (value) =>
  /^[a-zA-Z]+(\s[a-zA-Z]*){0,2}$/.test(value) || value.length === 0;

// Common validation method
Yup.addMethod(Yup.string, "noLeadingSpace", function (field) {
  return this.test({
    name: "no-leading-space",
    message: `${field} cannot start with a space`,
    test: function (value) {
      if (value) {
        return !value.startsWith(" ");
      }
      return true;
    },
  });
});

Yup.addMethod(Yup.string, "alphanumericOnly", function (field) {
  return this.test({
    name: "alphanumeric-only",
    message: `${field} should contain only alphanumeric characters`,
    test: function (value) {
      if (value) {
        return /^[a-zA-Z0-9\s]+$/.test(value);
      }
      return true;
    },
  });
});

Yup.addMethod(Yup.string, "alphabetOnly", function (field) {
  return this.test({
    name: "alpha-only",
    message: `${field} should contain only alphabetic characters`,
    test: function (value) {
      if (value) {
        return /^[a-zA-Z\s]+$/.test(value);
      }
      return true; // Empty value is considered valid
    },
  });
});

//   Admin Login ValidationSchema
export const adminLoginValidationSchema = Yup.object().shape({
  email: Yup.string()
    .email("Email is not valid")
    .matches(
      /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/,
      "Email is not valid"
    )
    .required("Email is required"),
  password: Yup.string().required("Password is required"),
});

export const adminGoalSetupAddSchema = Yup.object().shape({
  type: Yup.string().required("Select an option: Steps or Calories"),
  duration: Yup.string().required(
    "Select an option: Daily, Weekly, or Monthly"
  ),
  min: Yup.number()
    .required("Min Steps is required")
    .integer("Min Steps must be an integer")
    .positive("Min Steps must be a positive number"),
  max: Yup.number()
    .required("Max Steps is required")
    .integer("Max Steps must be an integer")
    .positive("Max Steps must be a positive number")
    .moreThan(Yup.ref("min"), "Max Steps must be higher than Min Steps"),
  token: Yup.number()
    .required("Token is required")
    .integer("token must be an integer"),
});

export const adminNFTSetupAddSchemaWithImage = Yup.object().shape({
  // nftImage: Yup.string().required("Please select any image"),
  nftMedia: Yup.string().required("Please select any image"),
  nftName: Yup.string().required("Please enter NFT name")
    .min(5, "You can not enter less than 5 characters")
    .max(200, "You can not enter more than 200 characters"),
  description: Yup.string()
    .required("Please enter description")
    .max(1000, "You can not enter more than 1000 characters"),
  level: Yup.string().required("Please enter level"),
  noOfUnit: Yup.string()
    .required("Please enter no of unit")
    .max(7, "No of unit allows maximum 7 characters")
  // eventType: Yup.string().required("Please enter NFT name"),
});

export const adminUserNFTSetupAddSchema = Yup.object().shape({
  durability: Yup.number().required("Durability is required"),
  energyPoints: Yup.number().required("Energy Point is required"),

  price: Yup.string()
    .required("Price is required")
    .matches(/^\w+(\.\w{0,3})?$/, "Please enter three digit after the decimal")
    .max(7, "price allows maximum 7 characters"),
  level: Yup.number().required("Level is required"),
});

export const subCategoryValidationSchema = Yup.object().shape({
  subCategoryName: Yup.string()
    .required("Sub category name is required")
    .test(
      "subCategoryName",
      "Sub category name allows only characters",
      textAndSpaceOnly
    )
    .max(50, "Category name allows maximum 50 characters"),
  categoryId: Yup.string().required("Category is required"),
});

//   store ValidationSchema
const digitsOnly = (value) =>
  /^\d*[\.{1}\d*]\d*$/.test(value) || value.length === 0;
const mobilePattern = (value) =>
  /^[1-9][0-9]*$/.test(value) || value.length === 0;
const textAndSpaceOnly2 = (value) =>
  /^[a-zA-Z]+(\s[a-zA-Z]*){0,2}$/.test(value) || value.length === 0;
export const storeValidationSchema = Yup.object().shape({
  storeName: Yup.string()
    .required("Name is required")
    .test("name", "Name allows only characters", textAndSpaceOnly2)
    .max(50, "Name allows maximum 50 characters"),
  email: Yup.string()
    .required("Email is required")
    .email("Invalid email")
    .matches(
      /^[_a-z0-9-]+(\.[_a-z0-9-]+)*@[a-z0-9-]+(\.[a-z0-9-]+)*(\.[a-z]{2,4})$/,
      "Invalid email"
    ),
  mobileNumber: Yup.string()
    .required("Mobile number is required")
    .test("inputEntry", "Mobile number should have digits only", digitsOnly)
    .test("inputEntry", "Mobile number is not valid", mobilePattern)
    .min(10, "Mobile number minimum length must be 10"),
  address: Yup.string()
    .required("Address is required")
    .matches(
      /^[a-zA-Z0-9\s,'-]*$/,
      // /^[a-zA-Z0-9]+(\s[a-zA-Z0-9]*)$/,
      "Please provide valid address"
    ),
  country: Yup.string().required("Country is required"),
  state: Yup.string().required("State is required"),
  city: Yup.string().required("City is required"),
});

//   warehouse ValidationSchema

export const warehouseValidationSchema = Yup.object().shape({
  warehouseName: Yup.string()
    .required("Name is required")
    .test("name", "Name allows only characters", textAndSpaceOnly)
    .max(50, "Name allows maximum 50 characters"),
  email: Yup.string()
    .required("Email is required")
    .email("Invalid email")
    .matches(
      /^[_a-z0-9-]+(\.[_a-z0-9-]+)*@[a-z0-9-]+(\.[a-z0-9-]+)*(\.[a-z]{2,4})$/,
      "Invalid email"
    ),
  mobileNumber: Yup.string()
    .required("Mobile number is required")
    .test("inputEntry", "Mobile number should have digits only", digitsOnly)
    .test("inputEntry", "Mobile number is not valid", mobilePattern)
    .min(10, "Mobile number minimum length must be 10"),
  address: Yup.string().required("Address is required"),
  country: Yup.string().required("Country is required"),
  state: Yup.string().required("State is required"),
  city: Yup.string().required("City is required"),
});

//   role ValidationSchema

export const roleValidationSchema = Yup.object().shape({
  role: Yup.string()
    .required("Role name is required")
    .test("roleName", "Role name allows only characters", textAndSpaceOnly)
    .max(20, "Role name allows maximum length 20"),
});

//   category ValidationSchema
export const categoryValidationSchema = Yup.object().shape({
  categoryName: Yup.string()
    .required("Category name is required")
    .test(
      "categoryName",
      "Category name allows only characters",
      textAndSpaceOnly
    )
    .max(50, "Category name allows maximum 50 characters"),
});

//   Transaction History ValidationSchema
export const walletValidationSchema = Yup.object().shape({
  totalTokens: Yup.number().required("Please enter token amount"),
});
//   forgot Password ValidationSchema
export const forgotPasswordValidationSchema = Yup.object().shape({
  email: Yup.string()
    .email("Email is not valid")
    .matches(
      /^[_a-z0-9-]+(\.[_a-z0-9-]+)*@[a-z0-9-]+(\.[a-z0-9-]+)*(\.[a-z]{2,4})$/,
      "Email is not valid"
    )
    .required("Email is required"),
});

//   reset Password ValidationSchema
export const adminResetPasswordValidationSchema = Yup.object().shape({
  otp: Yup.string()
    .required("OTP is required")
    .test("inputEntry", "Please enter numberic OTP", digitsOnly)
    .min(4, "Please enter 4 digits OTP")
    .max(4, "Please enter 4 digits OTP"),
  password: Yup.string()
    .required("Password is required")
    .max(12, "Maximum length 12 characters")
    .matches(
      // /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/,
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*()-_+=])[A-Za-z\d!@#$%^&*()-_+=]{8,}$/,
      "Password length should be minimum 8 character including one capital letter, one small letter, one number, and one special character, ex. 99@Inchroad"
    ),
  confirmPassword: Yup.string()
    .required("Confirm Password is required")
    .max(12, "Maximum length 12 characters")
    .oneOf(
      [Yup.ref("password"), null],
      "Confirm password must match with password"
    ),
});
//   change Password ValidationSchema
export const changePasswordValidationSchema = Yup.object().shape({
  oldPassword: Yup.string().required("Old password is required"),
  newPassword: Yup.string()
    .required("New password is required")
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/,
      "Password length should be minimum 8 character including one capital letter, one small letter, one number, and one special character, ex. 99@Inchroad"
    ),
  confirmPassword: Yup.string()
    .required("Confirm Password is required")
    .oneOf(
      [Yup.ref("newPassword"), null],
      "Confirm password must match with password"
    ),
});
// profile ValidationSchema
export const profileValidationSchema = Yup.object().shape({
  adminName: Yup.string()
    .required("Name is required")
    .test(
      "adminName",
      "Name allows only characters and space between",
      textAndSpaceOnly
    )
    .max(20, "Full Name allows maximum length 20"),
  email: Yup.string()
    .required("Email is required")
    .email("Email is not valid")
    .matches(
      /^[_a-z0-9-]+(\.[_a-z0-9-]+)*@[a-z0-9-]+(\.[a-z0-9-]+)*(\.[a-z]{2,4})$/,
      "Email is not valid"
    ),
  phoneNumber: Yup.string()
    .required()
    .min(10, "Mobile Number minimum length must be 10"),
});
//   add Employee ValidationSchema
export const addEmployeeValidationSchema = Yup.object().shape({
  name: Yup.string()
    .required("Name is required")
    .test("name", "Name allows only characters", textAndSpaceOnly)
    .max(50, "Name allows maximum 50 characters"),
  email: Yup.string()
    .required("Email is required")
    .email("Invalid email")
    .matches(
      /^[_a-z0-9-]+(\.[_a-z0-9-]+)*@[a-z0-9-]+(\.[a-z0-9-]+)*(\.[a-z]{2,4})$/,
      "Invalid email"
    ),

  phoneNumber: Yup.string()
    .required("Mobile number is required")
    .test("inputEntry", "Mobile number should have digits only", digitsOnly)
    .test("inputEntry", "Mobile number is not valid", mobilePattern)
    .min(10, "Mobile number minimum length must be 10"),
  address: Yup.string().required("Address is required"),
  country: Yup.string().required("Country is required"),
  role: Yup.string().required("Role is required"),
  storeId: Yup.string().required("Store is required"),
  password: Yup.string().when("isEdit", {
    is: false,
    then: () =>
      Yup.string()
        .required("Password is required")
        .matches(
          /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/,
          "Password length should be minimum 8 character including one capital letter, one small letter, one number, and one special character, ex. 99@Inchroad"
        ),
    otherwise: () =>
      Yup.string()
        .nullable()
        .matches(
          /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/,
          "Password length should be minimum 8 character including one capital letter, one small letter, one number, and one special character, ex. 99@Inchroad"
        ),
  }),
  confirmPassword: Yup.string()
    // .required("Confirm Password is required")
    .when("isEdit", {
      is: false,
      then: () =>
        Yup.string()
          .required("Confirm password is required")
          .oneOf(
            [Yup.ref("password"), null],
            "Confirm password must match with password"
          ),
      otherwise: () =>
        Yup.string()
          .nullable()
          .oneOf(
            [Yup.ref("password"), null],
            "Confirm password must match with password"
          ),
    }),
});
//   setting ValidationSchema
export const settingValidationSchema = Yup.object().shape({
  ReferralToken: Yup.string().required("Referral token is required"),
  SignupToken: Yup.string().required("Signup token is required"),
  SignupProfileCompletionToken: Yup.string().required(
    "Signup profile completion token is required"
  ),
  referralProfileCompletionToken: Yup.string().required(
    "Referral profile completion token is required"
  ),
});

//nft stack validation
export const nftStackValidationSchema = Yup.object().shape({
  duration: Yup.string().required("Duration is required"),
  token: Yup.string().required("Token is required"),
});

//  update User ValidationSchemaStep
const charSpaceNumber = (value) => /^[a-zA-Z0-9_.-]*$/.test(value);
export const updateUserValidationSchema = Yup.object().shape({
  firstName: Yup.string()
    .required("Fullname is required")
    .test("firstName", "Fullname allows only characters", textAndSpaceOnly)
    .min(5, "Please enter 5 character at least")
    .max(20, "Fullname allows maximum length 20"),
  userName: Yup.string()
    .required("Username is required")
    .test(
      "userName",
      "Username allows only characters and number",
      charSpaceNumber
    )
    .min(5, "Please enter 5 character at least")
    .max(20, "Username allows maximum length 20"),
  mobileNumber: Yup.string("Please enter Mobile number.")
    .min(3, 'Please enter Mobile number.'),
  // phoneNumber: Yup.string()
  //   .required("Mobile number is required")
  //   .min(10, "Mobile number should be 10 digits")
  //   .max(10, "Mobile number should be 10 digits"),
  country: Yup.string().required("Country is required"),
  dateOfBirth: Yup.string().required("Date of birth is required"),
  gender: Yup.string().required("Gender is required"),
  age: Yup.string()
    // .required("Age is required")
    .max(3, "Age allows only up to 3 digits"),
  height: Yup.object().shape({
    unit: Yup.string().required("Unit is required"),
    // cm: Yup.string().test("cmRequired", "CM is required", function (value) {
    //   const { unit } = this.parent;
    //   if (unit === "cms") {
    //     return value && value.trim() !== "";
    //   }
    //   return true;
    // }),
    // mm: Yup.string().test("cmRequired", "MM is required", function (value) {
    //   const { unit } = this.parent;
    //   if (unit === "cms") {
    //     return value && value.trim() !== "";
    //   }
    //   return true;
    // }),
    // fit: Yup.string().test("cmRequired", "Fit is required", function (value) {
    //   const { unit } = this.parent;
    //   if (unit === "ft") {
    //     return value && value.trim() !== "";
    //   }
    //   return true;
    // }),
    // inch: Yup.string().test(
    //   "cmRequired",
    //   "Inches is required",
    //   function (value) {
    //     const { unit } = this.parent;
    //     if (unit === "ft") {
    //       return value && value.trim() !== "";
    //     }
    //     return true;
    //   }
    // ),
    fit: Yup.string().when("unit", {
      is: "ft",
      then: () => Yup.string().required("Fit is required"),
      otherwise: () => Yup.string().nullable(),
    }),
    inch: Yup.string().when("unit", {
      is: "ft",
      then: () => Yup.string().required("Inch is required"),
      otherwise: () => Yup.string().nullable(),
    }),
    cm: Yup.string().when("unit", {
      is: "cms",
      then: () => Yup.string().required("Cm is required"),
      otherwise: () => Yup.string().nullable(),
    }),
    mm: Yup.string().when("unit", {
      is: "cms",
      then: () => Yup.string().required("Mm is required"),
      otherwise: () => Yup.string().nullable(),
    }),
  }),
  weight: Yup.object().shape({
    unit: Yup.string().required("Unit is required"),
    // lbs: Yup.string().test(
    //   "lbsRequired",
    //   "LBS is required",
    //   function (value) {
    //     const { unit } = this.parent;
    //     if (unit === "lbs") {
    //       return value && value.trim() !== "";
    //     }
    //     return true;
    //   }
    // ),
    // oz: Yup.string().test("ozRequired", "OZ is required", function (value) {
    //   const { unit } = this.parent;
    //   if (unit === "lbs") {
    //     return value && value.trim() !== "";
    //   }
    //   return true;
    // }),
    // kilo: Yup.string().test("kgRequired", "KG is required", function (value) {
    //   const { unit } = this.parent;
    //   if (unit === "kg") {
    //     return value && value.trim() !== "";
    //   }
    //   return true;
    // }),
    // gram: Yup.string().test(
    //   "gramRequired",
    //   "Gram is required",
    //   function (value) {
    //     const { unit } = this.parent;
    //     if (unit === "kg") {
    //       return value && value.trim() !== "";
    //     }
    //     return true;
    //   }
    // ),
    lbs: Yup.string().when("unit", {
      is: "lbs",
      then: () => Yup.string().required("LBS is required"),
      otherwise: () => Yup.string().nullable(),
    }),
    oz: Yup.string().when("unit", {
      is: "lbs",
      then: () => Yup.string().required("OZ is required"),
      otherwise: () => Yup.string().nullable(),
    }),
    kilo: Yup.string().when("unit", {
      is: "kg",
      then: () => Yup.string().required("Kilo is required"),
      otherwise: () => Yup.string().nullable(),
    }),
    gram: Yup.string().when("unit", {
      is: "kg",
      then: () => Yup.string().required("Gram is required"),
      otherwise: () => Yup.string().nullable(),
    }),
  }),
  routine: Yup.string().required("Routine is required"),
  foodPreference: Yup.string().required("Food preference is required"),
  diet: Yup.string().required("Diet is required"),
  goal: Yup.array().min(1, "Select at least one goal"),
  healthChallanges: Yup.array().min(1, "Select at least one Health Challanges"),
  kycType: Yup.string().required("Identification document type is required"),
  uniqueIdNo: Yup.string()
    .required("Unique identification number is required")
    .max(15, "Unique identification number allows maximum 15 digits"),
  kycDocument: Yup.mixed()
    // .nullable()
    // .notRequired()
    // .test(
    //   "FILE_FORMAT",
    //   "allow only jpg,jpeg,png,pdf file",
    //   (value) =>
    //     !value ||
    //     (["jpg", "jpeg", "png", "pdf"].includes(
    //       value?.name?.substr(value?.name?.lastIndexOf(".") + 1)
    //     ) &&
    //       ["image/jpg", "image/jpeg", "image/png", "application/pdf"].includes(
    //         value?.type
    //       ))
    // )
    .nullable()
    .notRequired()
    .test(
      "FILE_FORMAT",
      "Only JPG, JPEG, PNG, and PDF formats are allowed",
      (value) =>
        !value ||
        (["jpg", "jpeg", "png", "pdf"].includes(
          value?.name?.split(".").pop().toLowerCase()
        ) &&
          ["image/jpeg", "image/png", "application/pdf"].includes(value?.type))
    )
    .test(
      "fileSize",
      "File size too large, max file size is 2.048 Mb",
      (file) => {
        if (file) {
          return file.size <= 2048000;
        } else {
          return true;
        }
      }
    ),
});

// product validation
export const productValidationSchema = Yup.object().shape({
  productType: Yup.string().required("Product type is required"),
  comboProducts: Yup.array()
    .when("productType", {
      is: "combo",
      then: () => Yup.array().min(2, "Please select more than one products"),
    }),
  ProductName: Yup.string()
    .required("Product name is required")
    .noLeadingSpace("Product name")
    .alphanumericOnly("Product name")
    .max(100, "Product name allows maximum length 50 characters"),
  productDetails: Yup.string()
    .required("Product details is required")
    .noLeadingSpace("Product details")
    // .alphanumericOnly("Product details")
    .max(500, "Product details allows maximum length 500 characters"),
  category: Yup.string().required("Category is required"),
  subcategory: Yup.string().required("Subcategory is required"),
  MRP: Yup.string()
    .required("MRP is required")
    .matches(/^[1-9]\d*(\.\d+)?$/, 'Invalid format of MRP'),
  DistributorPrice: Yup.string().required("Distributor price is required"),
  SKUCreation: Yup.string()
    .required("SKU creation is required")
    .noLeadingSpace("SKU creation")
    .alphanumericOnly("SKU creation"),
  purchasePrice: Yup.string().required("Purchase price is required"),
  warehousePrice: Yup.string().required("Warehouse price is required"),
  healthCenterPrice: Yup.string().required("Health center price is required"),
  healthStorePrice: Yup.string().required("Health store price is required"),
  HSN: Yup.string()
    .required("HSN is required")
    .matches(/^^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d]+$/, 'Invalid format of HSN'),
  GSTTaxRate: Yup.string().required("GST tax rate is required"),
  VATTAXRATE: Yup.string().required("VAT tax rate is required"),
  color: Yup.string()
    //.required("Color is required")
    .min(3, "Minimum length must be 3 characters")
    .max(50, "Maximum length 50 characters")
    .noLeadingSpace("Color")
    .matches(/^[a-zA-Z0-9,/-]+$/, 'Special characters not allowed'),
  // flavor: Yup.string()
  //   .required("Flavor is required")
  //   .noLeadingSpace("Flavor")
  //   .alphabetOnly("Flavor"),
  size: Yup.string()
    // .required("Size is required")
    // .alphanumericOnly("Size")
    // .min(5, "Minimum length must be 5")
    .max(15, "Maximum length 15 characters"),

  // volume: Yup.string()
  //   .required("Volume is required")
  //   .noLeadingSpace("Volume")
  //   .alphanumericOnly("Flavor"),
  productBatchNumber: Yup.string()
    .required("Product batch number is required")
    .min(5, "Minimum length must be 5")
    .max(50, "Maximum length 50 characters"),
  // .matches(/^[a-zA-Z0-9/-]+$/, 'Invalid format of product batch number'),
  brandId: Yup.string().required("Brand is required"),
  // affiliateCommisionPercentage: Yup.string().required(
  //   "Affiliate commission percentage is required"
  // ),
  // style: Yup.string()
  //   .required("Style is required")
  //   .noLeadingSpace("Style")
  //   .alphanumericOnly("Style"),
  manufacturingDate: Yup.string().required("Manufacturing date is required"),
  expiryDate: Yup.string().required("Expiry date is required"),

  productImage: Yup.mixed()
    .required("Image is required")
    .test({
      test: (value) => Array.isArray(value) && value.length <= 5,  // Check if it's an array and not greater than 5
      message: 'Only 5 images are allowed.'
    })
    .test("fileSize", "File Size is too large, max file size is 5 Mb", (value) => {
      if (value && value?.length > 0) {
        for (let i = 0; i < value.length; i++) {
          if (value[i].size > 5242880) {
            return false;
          }
        }
      }
      return true;
    })
    .test("FILE_TYPE", "Please upload an image in JPEG, JPG, GIF, PNG, SVG, BMB, WEBP format.", (value) => {
      if (value && value.length > 0) {
        for (let i = 0; i < value.length; i++) {
          if (value[i]) {
            if (value[i].type !== undefined) {
              return (
                value[i].type === "image/jpeg" ||
                value[i].type === "image/jpg" ||
                value[i].type === "image/png" ||
                value[i].type === "image/svg+xml" ||
                value[i].type === "image/bmp" ||
                value[i].type === "image/webp" ||
                value[i].type === "image/gif"
              );
            } else {
              let format = ["jpeg", "jpg", "gif", "png", "svg", "bmp", "webp"]
              let fileFormat = value[i]?.split(".")
              return format?.includes(fileFormat[fileFormat?.length - 1])
            }
          } else {
            return true;
          }
        }
      }
      return true;
    }
    ),

  // productImage: Yup.mixed()
  //   .required("Image is required")
  //   .test(
  //     "FILE_TYPE",
  //     "Please upload an image in JPEG, JPG, GIF, PNG, SVG, BMB, WEBP format.",
  //     (value) => {
  //       if (value) {
  //         if(value.type !== undefined){
  //           // console.log(value.type, '  = value.type');
  //           return (
  //             value.type === "image/jpeg" ||
  //             value.type === "image/jpg" ||
  //             value.type === "image/png" ||
  //             value.type === "image/svg+xml" ||
  //             value.type === "image/bmp" ||
  //             value.type === "image/webp" ||
  //             value.type === "image/gif" 
  //           );
  //         }else{
  //           let format = ["jpeg" , "jpg" , "gif", "png", "svg", "bmp", "webp"]
  //           let fileFormat = value?.split(".")
  //           console.log(fileFormat, '  = fileFormat');
  //           return format?.includes(fileFormat[fileFormat?.length - 1])
  //         }
  //       } else {
  //         return true;
  //       }
  //     }
  //   )
  //   .test("FILE_SIZE", "File size too small, min file size should be 100 Kb", (value) => {
  //     if(value?.size != undefined){
  //       return !value || (value && value?.size >= 100000);
  //     } else{
  //       return true;
  //     }
  //   })
  //   .test("FILE_SIZE", "File size too large, max file size is 5 Mb", (value) => {
  //     if(value?.size != undefined){
  //       return !value || (value && value?.size <= 5000000);
  //     } else{
  //       return true;
  //     }
  //   }),

});
// Add supplier ValidationSchema
export const addSupplierValidationSchema = Yup.object().shape({
  name: Yup.string()
    .required("Name is required")
    .test("name", "Name allows only characters", textAndSpaceOnly)
    .max(50, "Name allows maximum 50 characters"),
  email: Yup.string()
    .required("Email is required")
    .email("Invalid email")
    .matches(
      /^[_a-z0-9-]+(\.[_a-z0-9-]+)*@[a-z0-9-]+(\.[a-z0-9-]+)*(\.[a-z]{2,4})$/,
      "Invalid email"
    ),
  phoneNumber: Yup.string()
    .required("Mobile number is required")
    .test("inputEntry", "Mobile number should have digits only", digitsOnly)
    .test("inputEntry", "Mobile number is not valid", mobilePattern)
    .min(10, "Mobile number minimum length must be 10"),
  address: Yup.string()
    .required("Address is required")
    .matches(
      /^[a-zA-Z0-9\s,'-]*$/,
      // /^[a-zA-Z0-9]+(\s[a-zA-Z0-9]*)$/,
      "Please provide valid address"
    ),
  country: Yup.string().required("Country is required"),
  role: Yup.string().required("Role is required"),
  password: Yup.string().when("isEdit", {
    is: false,
    then: () =>
      Yup.string()
        .required("Password is required")
        .matches(
          /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/,
          "Password length should be minimum 8 character including one capital letter, one small letter, one number, and one special character, ex. 99@Inchroad"
        ),
    otherwise: () =>
      Yup.string()
        .nullable()
        .matches(
          /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/,
          "Password length should be minimum 8 character including one capital letter, one small letter, one number, and one special character, ex. 99@Inchroad"
        ),
  }),
  confirmPassword: Yup.string()
    // .required("Confirm Password is required")
    .when("isEdit", {
      is: false,
      then: () =>
        Yup.string()
          .required("Confirm password is required")
          .oneOf(
            [Yup.ref("password"), null],
            "Confirm password must match with password"
          ),
      otherwise: () =>
        Yup.string()
          .nullable()
          .oneOf(
            [Yup.ref("password"), null],
            "Confirm password must match with password"
          ),
    }),
});

// About us ValidationSchema
export const aboutUsValidationSchema = Yup.object().shape({
  description: Yup.string().required("About us is required"),
});

// Term & Condition ValidationSchema
export const termConditionValidationSchema = Yup.object().shape({
  description: Yup.string().required("Terms & conditions is required"),
});

// Privacy/Policy ValidationSchema
export const privacyPolicyValidationSchema = Yup.object().shape({
  description: Yup.string().required("Privacy policy is required"),
});

// Admin add user validationSchema

export const adminAddUserValidationSchema = Yup.object().shape({
  firstName: Yup.string()
    .required("Full name is required")
    .test("firstName", "Full name allows only characters", textAndSpaceOnly)
    .min(3, "Full name more then 3 characters")
    .max(50, "Full name allows maximum length 50"),
  userName: Yup.string()
    .required("Username is required")
    // .test(
    //   "userName",
    //   "Username allows only characters and number",
    //   charSpaceNumber
    // )
    // .min(3, "Username more then 3 characters")
    // .max(20, "Username allows maximum length 20")
    // .matches(/^[a-zA-Z]+[_\-\.0-9]*$/, 'Username must start with a letter and may contain letters, numbers, _, -, or .'),
    .matches(/^[a-z\d_\s]+$/, 'Uppercase value and Special characters are not allowed')
    .matches(/^(\S+$)/g, 'This field is not accept space')
    .min(3, 'Username should be Minimum 3 characters long')
    .max(20, 'Username should be Maximum 50 characters long'),
  registerAs: Yup.string().required("User type is required"),
  email: Yup.string()
    .email("Email is not valid")
    .matches(
      /^[_a-z0-9-]+(\.[_a-z0-9-]+)*@[a-z0-9-]+(\.[a-z0-9-]+)*(\.[a-z]{2,4})$/,
      "Email is not valid"
    )
    .required("Email is required"),
  mobileNumber: Yup.string("Please enter Mobile number.")
    .min(3, 'Please enter Mobile number.'),
  // phoneNumber: Yup.string()
  //   .nullable()
  //   .min(10, "Mobile number should be 10 digits")
  //   .max(10, "Mobile number should be 10 digits"),
  country: Yup.string().required("Country is required"),
  password: Yup.string()
    .required("Password is required")
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/,
      "Password length should be minimum 8 character including one capital letter, one small letter, one number, and one special character, ex. 99@Inchroad"
    ),
  confirmPassword: Yup.string()
    .required("Confirm password is required")
    .oneOf(
      [Yup.ref("password"), null],
      "Confirm password must match with password"
    ),
});

// Add Challenge validation
export const  AddChallengeValidationSchema = Yup.object().shape({
  challengeType: Yup.string()
    .required("Challenge type is required"),
  amount : Yup.string().when('challengeType',{
    is:"Paid",
    then:()=>Yup.string().required("Amount is required")
  }),
  challengeTitle: Yup.string()
    .required("Challenge title is required")
    // .noLeadingSpace("Challenge title")
    .alphanumericOnly("Challenge title")
    .trim('Challenge title is not accept extra space')
    .strict(true)
    .matches(/^[a-zA-Z0-9][a-zA-Z0-9\s']*$/, {
      message: "Please enter valid Challenge title.",
      excludeEmptyString: true,
    })
    .max(100, "Challenge title allows maximum length 100 characters"),
  startDate: Yup.string().required("Start date is required"),
  endDate: Yup.string().required("End date is required"),
  StartTime: Yup.string().required("Start time is required"),
  endTime: Yup.string().required("End time is required"),
  target: Yup.string().required("Select your duration"),
  rules: Yup.string()
    .required("Rules are required"),
  // .matches(/^[a-zA-Z0-9][a-zA-Z0-9\s']*$/, {
  //   message: "Please enter valid Rules.",
  //   excludeEmptyString: true,
  // }),
  minimumParticipant: Yup.string()
    .required("Minimum participant is required")
    .matches(/^[1-9][0-9]*$/, {
      message: "Should not start with zero.",
      excludeEmptyString: true,
    }),
  maximumParticipant: Yup.string()
    .required("Maximum participant is required")
    .matches(/^[1-9][0-9]*$/, {
      message: "Should not start with zero.",
      excludeEmptyString: true,
    }),
  image: Yup.mixed()
    .required("Image is required")
    .test(
      "FILE_TYPE",
      "Please upload an image in JPEG, JPG, or PNG format.",
      (value) => {
        if (value) {
          if (value.type !== undefined) {
            return (
              value.type === "image/jpeg" ||
              value.type === "image/jpg" ||
              value.type === "image/png"
            );
          } else {
            let format = ["jpeg", "jpg", "png"]
            let fileFormat = value?.split(".")
            return format?.includes(fileFormat[fileFormat?.length - 1])
          }

        } else {
          return true;
        }
      }
    )
    .test("FILE_SIZE", "File size too large, max file size is 2.048 Mb", (value) => {
      if (value?.size != undefined) {
        return !value || (value && value?.size <= 2048000);
      } else {
        return true;
      }
    }),
});

export const AddWinnerDetailsSchema = Yup.object().shape({
  winnerDeclarationArray: Yup.array()
  .of(
    Yup.object().shape({
      winnerNumber: Yup.string(), 
      winnerAmountType: Yup.string().required('Select amount type'),
      tokens : Yup.string().when("winnerAmountType",{
        is:(val) => ["Token", "Both"].includes(val),
        then:()=>Yup.string().required("Tokens is required")
      }),
      warehouseId : Yup.string().when("winnerAmountType",{
        is:(val) => ["Product", "Both"].includes(val),
        then:()=>Yup.string().required("Select warehouse")
      }),
      productId: Yup.string().when(["winnerAmountType", "warehouseId"], {
        is: (winnerAmountType, warehouseId) => winnerAmountType === "Product" && warehouseId,
        then:()=> Yup.string().required("Select product"),
      }),
    })
  )
  .required('Distance cost is required') 
});

// Add Coupon Code Validation Schema
export const addCouponValidationSchema = Yup.object().shape({
  couponName: Yup.string()
    .required("Coupon name is required")
    .matches(/^(?!\s)(?=.*\S)[a-zA-Z0-9\s.'-]+(?<!\s)$/, {
      message: "Please enter only alphabets and numeric value without leading or trailing white spaces.",
      excludeEmptyString: true,
    })
    .min(2, "Coupon name allows minimum length 2 characters")
    .max(50, "Coupon name allows maximum length 50 characters")
    .alphanumericOnly("Only alpha numeric value"),
  couponCode: Yup.string()
    .required("Coupon code is required")
    // .alphanumericOnly("Only alpha numeric value")
    // .noLeadingSpace("Coupon code")
    .matches(/^[a-zA-Z0-9\/-]+$/, {
      message: "Special characters are not allow but accept only '/' and '-'",
      excludeEmptyString: true,
    })
    .min(5, "Coupon code allows minimum length 5 characters")
    .max(14, "Coupon code allows maximum length 14 characters"),
  couponType: Yup.string().required("Coupon type is required"),
  couponAmount: Yup.string().required("Coupon amount is required"),
  couponStartDate: Yup.string().required("Start date is required"),
  couponEndDate: Yup.string().required("End date is required"),
  categoryId: Yup.array().min(1, "Select at least one category"),
  // subCategoryId: Yup.array().min(1, "Select at least one sub category"),
  minOrderAmount: Yup.string()
    .required("Min order amount is required")
    .matches(/^\d+(\.\d{0,2})?$/, 'Min order amount must be a number with up to two decimal places'),
  numberOfTimesUsage: Yup.string()
    .required("No of times Usage is required")
    .matches(/^(?!0$)\d+$/, {
      message: "No of times Usage must be numeric and cannot be zero",
      excludeEmptyString: true,
    })
    .max(5, "No of times Usage allows maximum length 5 characters"),
});


export const validateMobileNo = (mobileNo, dialCode) => {
  const parsedPhoneNumber = parsePhoneNumber(mobileNo, dialCode);
  // console.log(parsedPhoneNumber, 'validation');
  if (!parsedPhoneNumber || !parsedPhoneNumber.isValid() || parsedPhoneNumber.isNonGeographic()) {
    return "Please enter a valid Mobile Number.";
  }
  if (/(\d)\1{3,}/.test(parsedPhoneNumber.number)) {
    return "Mobie Number cannot have continuous single numbers";
  }
  return "";

};

export const addEditNutritionPlanSchema = Yup.object().shape({
  recipeType: Yup.string().required("Recipe type is required"),
  recipeName: Yup.string()
    .required("Recipe name is required")
    .min(2, "Recipe name should contain more then 2 characters")
    .max(200, "Recipe name allows maximum length 200")
    // .noLeadingSpace("Recipe name")
    .trim('Recipe name should not start with space.'),
    // .strict(true)
    // .matches(/^[a-zA-Z]+(?:\s[a-zA-Z]+)*$/, 'Numbers, special characters and extra space are not allowed'),
  // ingredients: Yup.string().required("Ingredients is required"),
  ingredients: Yup.string()
    .required("Ingredients is required")
    .min(2, "Ingredients should contain more then 2 characters")
    .max(200, "Ingredients allows maximum length 200")
    .noLeadingSpace("Ingredients"),
    // .matches(/^[a-zA-Z]+[_\-\.0-9]*$/, 'Ingredients is required'),
    //.matches(/^([a-zA-Z]+(\s[a-zA-Z]+[.]*)*([,](\s[a-zA-Z]+[.]*)*){0,2})$/, 'Ingredients can only allow alphabetic characters, full stops and commas'),

  // kcalPerMeal: Yup.string().required("Kcal per meal is required"),
  kcalPerMeal: Yup.string()
    .required("Cal per meal is required")
    .noLeadingSpace("Cal per meal")
    .matches(/^(?!0)[a-zA-Z0-9\s]*$/,"Cal per meal can only accept alphanumeric"),
    // .matches(/^[a-zA-Z]+[_\-\.0-9]*$/, 'Ingredients is required'),
    //.matches(/^(\d+(\.\d+)*)$/, 'Cal can only allow numeric values.'),

  fatsPerMeal: Yup.string()
    .required("Fats per meal is required")
    .noLeadingSpace("Fats per meal")
    .matches(/^(?!0)[a-zA-Z0-9\s]*$/,"Fats per meal can only accept alphanumeric"),
    // .matches(/^[a-zA-Z]+[_\-\.0-9]*$/, 'Ingredients is required'),
    //.matches(/^(\d+(\.\d+)*)$/, 'Fats can only allow numeric values.'),

  carbsPerMeal: Yup.string()
    .required("Carbs per meal is required")
    .noLeadingSpace("Carbs per meal")
    .matches(/^(?!0)[a-zA-Z0-9\s]*$/,"Carbs per meal can only accept alphanumeric"),
    // .matches(/^[a-zA-Z]+[_\-\.0-9]*$/, 'Ingredients is required'),
    //.matches(/^(\d+(\.\d+)*)$/, 'Carbs can only allow numeric values.'),

  proteinsPerMeal: Yup.string().
    required("Proteins per meal is required")
    .noLeadingSpace("Proteins per meal")
    .matches(/^(?!0)[a-zA-Z0-9\s]*$/,"Protein per meal can only accept alphanumeric"),
    // .matches(/^[a-zA-Z]+[_\-\.0-9]*$/, 'Ingredients is required'),
    //.matches(/^(\d+(\.\d+)*)$/, 'Proteins can only allow numeric values.'),

  dietType: Yup.string()
    // .noLeadingSpace("Diet Type")
    // .matches(/^[a-zA-Z]+[_\-\.0-9]*$/, 'Ingredients is required'),
    // .matches(/^(\d+(\.\d+)*)$/, 'Diet Type can only allow numeric values.'),
    .required("Diet Type is required")
    .min(2, "Diet Type should contain more then 2 characters")
    .max(200, "Diet Type allows maximum length 200")
    .noLeadingSpace("Diet Type"),
    // .matches(
    //   /^(?=.*[a-zA-Z])(?=.*[0-9])(?=.*[^a-zA-Z0-9]).+$/,
    //   "Diet Type must contain at least one character, one number, and one special character"
    // ),

  goalForRecipe: Yup.string()
    .required("Goal for Recipe is required")
    .min(2, "Goal for Recipe should contain more then 2 characters")
    .max(500, "Goal for Recipe allows maximum length 500")
    .noLeadingSpace("Goal for Recipe"),
    // .matches(
    //   /^(?=.*[a-zA-Z])(?=.*[0-9])(?=.*[^a-zA-Z0-9]).+$/,
    //   "Goal for Recipe must contain at least one character, one number, and one special character"
    // ),
  // allergens:Yup.string().required("Allergens is required"),
  // diseases:Yup.string().required("Diseases is required"),
  // diseases: Yup.string()
  //   .required("Diseases is required")
  //   .min(2, "Diseases more then 2 characters")
  //   .max(200, "Diseases allows maximum length 200")
  //   .noLeadingSpace("Diseases")
  //   // .matches(/^[a-zA-Z]+[_\-\.0-9]*$/, 'Diseases is required'),
  //   .matches(/^([a-zA-Z]+(\s[a-zA-Z]+[.]*)*([,](\s[a-zA-Z]+[.]*)*){0,2})$/, 'Diseases can only allow alphabetic characters, full stops and commas'),
  recipeImage: Yup.mixed()
    .required("Recipe Image is required")
    .test(
      "FILE_TYPE",
      "Please upload an image in JPEG, JPG, or PNG format.",
      (value) => {
        if (value) {
          if (value.type !== undefined) {
            return (
              value.type === "image/jpeg" ||
              value.type === "image/jpg" ||
              value.type === "image/png"
            );
          } else {
            let format = ["jpeg", "jpg", "png"]
            let fileFormat = value?.split(".")
            return format?.includes(fileFormat[fileFormat?.length - 1])
          }

        } else {
          return true;
        }
      }
    )
    .test("FILE_SIZE", "File size too large, max file size is 2.048 Mb", (value) => {
      if (value?.size != undefined) {
        return !value || (value && value?.size <= 2048000);
      } else {
        return true;
      }
    }),
})

export const adminFaqListAddSchema = Yup.object().shape({
  title: Yup.string().required("Please enter question")
    .noLeadingSpace("Question")
    .max(250, "FAQ Title allows maximum length 250"),
  description: Yup.string().required("Please enter answer")
    .noLeadingSpace("Description")
    .max(2000, "FAQ Description allows maximum length 2000"),
});

export const addProductStockSchema = Yup.object().shape({
  productId: Yup.string().required('Please select a product'),
  actionType: Yup.string().required('Please select a action type'),
  quantity: Yup.string().required("Please enter quantity")
})
export const transferProductStockSchema = Yup.object().shape({
  toWarehouseId: Yup.string().required('Please select a warehouse'),
  productId: Yup.string().required('Please select a product'),
  quantity: Yup.string().required("Please enter quantity")
})

export const NFTRepairAddEditSchema = Yup.object().shape({
  nftLevel: Yup.number().required("NFT Level is required"),
  percentage: Yup.number().required("Percentage is required"),
  energyPoint: Yup.number().required("Energy point is required"),
  price: Yup.string()
    .required("Price is required")
    .matches(/^\w+(\.\w{0,6})?$/, "Please enter max six digit after the decimal")
    .max(10, "price allows maximum 10 characters"),
});

export const addSubAdminValidationSchema = Yup.object().shape({
  adminName: Yup.string()
    .required("Name is required")
    .test("name", "Name allows only characters", textAndSpaceOnly)
    .max(50, "Name allows maximum 50 characters"),
  email: Yup.string()
    .required("Email is required")
    .email("Invalid email")
    .matches(
      /^[_a-z0-9-]+(\.[_a-z0-9-]+)*@[a-z0-9-]+(\.[a-z0-9-]+)*(\.[a-z]{2,4})$/,
      "Invalid email"
    ),
  roleId: Yup.string()
    .required("Role is required"),
  phoneNumber: Yup.string()
    .required("Mobile number is required")
    .test("inputEntry", "Mobile number should have digits only", digitsOnly)
    .test("inputEntry", "Mobile number is not valid", mobilePattern)
    .min(10, "Mobile number minimum length must be 10"),

   password: Yup.string().when("isEdit", {
    is: false,
    then: () =>
      Yup.string()
        .required("Password is required")
        .matches(
          /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/,
          "Password length should be minimum 8 character including one capital letter, one small letter, one number, and one special character, ex. 99@Inchroad"
    ),
  }),
  confirmPassword: Yup.string().when("isEdit", {
    is: false,
    then: () =>
      Yup.string()
        .required("Confirm password is required")
        .matches(
          /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/,
          "Password length should be minimum 8 character including one capital letter, one small letter, one number, and one special character, ex. 99@Inchroad"
    ),
  })
});

export const adminCenterTypeAddSchema = Yup.object().shape({
  name: Yup.string().required("Center name is required")
  .min(3, "Center name minimum length is 3")
  .max(50, "Center name allows maximum 50 characters"),
});

export const addEditAmenitiySchema = Yup.object().shape({
  centerTypeId: Yup.string().required("Center type is required"),
  amenitiesName: Yup.string()
    .required("Amenities name is required")
    .min(2, "Amenities name should contain more then 2 characters")
    .max(100, "Amenities name allows maximum length 100")
    .trim('Amenities name should not start with space.'),
  image: Yup.mixed()
    .required("Amenities Image is required")
    .test(
      "FILE_TYPE",
      "Please upload an image in JPEG, JPG, or PNG format.",
      (value) => {
        if (value) {
          if (value.type !== undefined) {
            return (
              value.type === "image/jpeg" ||
              value.type === "image/jpg" ||
              value.type === "image/png"
            );
          } else {
            let format = ["jpeg", "jpg", "png"]
            let fileFormat = value?.split(".")
            return format?.includes(fileFormat[fileFormat?.length - 1])
          }
        } else {
          return true;
        }
      }
    )
    .test("FILE_SIZE", "File size too large, max file size is 2.048 Mb", (value) => {
      if (value?.size != undefined) {
        return !value || (value && value?.size <= 2048000);
      } else {
        return true;
      }
    }),
})

export const addEditEquipmentSchema = Yup.object().shape({
  centerTypeId: Yup.string().required("Center type is required"),
  equipmentName: Yup.string()
    .required("Equipment name is required")
    .min(2, "Equipment name should contain more then 2 characters")
    .max(100, "Equipment name allows maximum length 100")
    .trim('Equipment name should not start with space.'),
  image: Yup.mixed()
    .required("Equipment Image is required")
    .test(
      "FILE_TYPE",
      "Please upload an image in JPEG, JPG, or PNG format.",
      (value) => {
        if (value) {
          if (value.type !== undefined) {
            return (
              value.type === "image/jpeg" ||
              value.type === "image/jpg" ||
              value.type === "image/png"
            );
          } else {
            let format = ["jpeg", "jpg", "png"]
            let fileFormat = value?.split(".")
            return format?.includes(fileFormat[fileFormat?.length - 1])
          }
        } else {
          return true;
        }
      }
    )
    .test("FILE_SIZE", "File size too large, max file size is 2.048 Mb", (value) => {
      if (value?.size != undefined) {
        return !value || (value && value?.size <= 2048000);
      } else {
        return true;
      }
    }),
})

export const addEditServiceSchema = Yup.object().shape({
  centerTypeId: Yup.string().required("Center type is required"),
  defaultPrice: Yup.string()
  .required("Default price is required"),
  serviceName: Yup.string()
    .required("Service name is required")
    .min(2, "Service name should contain more then 2 characters")
    .max(100, "Service name allows maximum length 100")
    .trim('Service name should not start with space.'),
  image: Yup.mixed()
    .required("Service Image is required")
    .test(
      "FILE_TYPE",
      "Please upload an image in JPEG, JPG, or PNG format.",
      (value) => {
        if (value) {
          if (value.type !== undefined) {
            return (
              value.type === "image/jpeg" ||
              value.type === "image/jpg" ||
              value.type === "image/png"
            );
          } else {
            let format = ["jpeg", "jpg", "png"]
            let fileFormat = value?.split(".")
            return format?.includes(fileFormat[fileFormat?.length - 1])
          }
        } else {
          return true;
        }
      }
    )
    .test("FILE_SIZE", "File size too large, max file size is 2.048 Mb", (value) => {
      if (value?.size != undefined) {
        return !value || (value && value?.size <= 2048000);
      } else {
        return true;
      }
    }),
})


// Add Coupon Code Validation Schema
export const addSponsoredByValidationSchema = Yup.object().shape({
  startDate: Yup.string().required("Start date is required"),
  endDate: Yup.string().required("End date is required"),
});